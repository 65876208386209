import { ErrorDialog } from '@breeze-ai/ui-library';

import {
  usePolicyFlowContext,
  useSetFlowError,
  useSetFlowStep,
} from '../../hooks/context-hooks';

export const PolicyFlowErrorDialog = () => {
  const { error } = usePolicyFlowContext();
  const setFlowStep = useSetFlowStep();
  const setFlowError = useSetFlowError();

  if (!error) {
    return null;
  }

  const { retry, fallbackStep, onConfirm, ...dialogProps } = error;

  const closeDialog = () => {
    setFlowError(undefined);
    setFlowStep(fallbackStep);
  };

  const onDialogAction = () => {
    closeDialog();
    onConfirm?.();
    retry?.();
  };

  return (
    <ErrorDialog
      visible={true}
      onClose={closeDialog}
      onConfirm={onDialogAction}
      {...dialogProps}
    />
  );
};
