import type { IconType } from 'react-icons';
import { FaShip, FaTrain, FaTruck } from 'react-icons/fa';
import { FaPlaneUp } from 'react-icons/fa6';

import { type ConveyanceType, type ShipmentLocation } from '../model/Shipment';
import { type QuoteCoveragePackage } from '../network/apis/quotes/types';

// This is not that great. We need a holistic approach to from
// configuration, including date limitations like the constants below.
export const ETD_MIN_DAYS_OFFSET_BREEZE = 20;
export const ETD_MIN_DAYS_OFFSET_WTW = 30;

export const CONVEYANCE_TYPE_ICONS: Record<ConveyanceType, IconType> = {
  sea: FaShip,
  air: FaPlaneUp,
  road: FaTruck,
  rail: FaTrain,
};

export const LOCATION_TYPES_BY_CONVEYANCE: Record<
  ConveyanceType,
  ShipmentLocation
> = {
  sea: 'port',
  air: 'port',
  road: 'place',
  rail: 'place',
};

export const COVERAGE_PACKAGES: Record<
  QuoteCoveragePackage,
  { label: string; badgeLabel: string; description: string }
> = {
  all_risks: {
    label: 'All risks',
    badgeLabel: 'All risk coverage',
    description:
      'Covers losses or damages due to any cause, except for those excluded',
  },
  fpa: {
    label: 'FPA',
    badgeLabel: 'FPA',
    description:
      '"Free of Particular Average" - limited coverage for partial cargo loss or damage',
  },
};
