import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { type ClaimEventLocationType } from '../../model/Claim';
import { type CurrencyDefinition } from '../../model/CurrencyValue';
import {
  type ConveyanceType,
  type SpecialConditionOptionType,
} from '../../model/Shipment';
import {
  type LabeledOption,
  type TitledOption,
} from '../../model/TitledOption';
import { get } from '../../network/apis/utils';
import { bffServiceUrl } from '../../network/netconfig';

export type AppConfiguration = {
  currencies: CurrencyDefinition[];
  incoterms: TitledOption[];
  container_modes: Record<ConveyanceType, TitledOption[]>;
  commodities: TitledOption[];
  special_conditions: SpecialConditionOptionType[];
  claim_location_types: LabeledOption<ClaimEventLocationType>[];
};

// TODO: Convert custom hook to use query factory structure instead of hook (https://tkdodo.eu/blog/the-query-options-api#query-factories)
export const configurationQueries = {
  application: queryOptions({
    queryKey: ['app-configuration'],
    queryFn: () => get<AppConfiguration>(`${bffServiceUrl}/configurations`),
  }),
};

export const useAppConfiguration = () => {
  const { data } = useSuspenseQuery(configurationQueries.application);
  return {
    ...data,
  };
};
