import { useLocation } from 'react-router-dom';
import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';
import invariant from 'tiny-invariant';

import { useUser } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type Location } from '../../../../network/apis/locations/types';
import {
  type QuoteDuplicationPayload,
  type QuoteSubmissionPayload,
  type QuoteUpdatePayload,
} from '../../../../network/apis/quotes/types';
import { useRouteParams } from '../../../../router/router-hooks';
import { type MapsApiPayload } from '../../../certificates/types';
import { getJourneyUpdatePayload } from '../../../components/RouteDetails/utils';
import { getUpdatedValue } from '../../../utils/forms';
import { type PolicyFlowUrlParams } from '../../context/types';
import { type QuoteFormData } from './QuoteForm';
import { isAnyFieldDirty } from './utils';

export const useQuoteFormLogic = () => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyFlowUrlParams>();
  const { pathname } = useLocation();

  const { isWtw } = usePlatform();

  const user = useUser();

  const isDuplicate = pathname.includes('/duplicate/');
  // TODO set route for quote update to /update/:quoteId and check pathname if isUpdate
  const isUpdate = !!quoteId && !isDuplicate;

  const getPayload = (
    form: FormApi<QuoteFormData, typeof zodValidator>,
  ): QuoteSubmissionPayload | QuoteUpdatePayload => {
    const formData = form.state.values;
    const formMeta = form.state.fieldMeta;

    // TODO: copied from useFormLogic under CertificateFlowPage;
    // move to shared place
    const getPlacePayload = (
      location?: Location | null,
    ): MapsApiPayload | null | undefined => {
      if (location === undefined) {
        return undefined;
      }
      if (location === null) {
        return null;
      }
      if (location.type === 'place') {
        return {
          place_id: location.place.place_id,
          session_token: location.place.session_token,
        };
      }

      return undefined;
    };

    const loadingPlace = getPlacePayload(formData.placeOfLoading);
    const deliveryPlace = getPlacePayload(formData.placeOfDelivery);
    const originPlace = getPlacePayload(formData.origin);
    const destinationPlace = getPlacePayload(formData.destination);
    const originPortCode =
      formData.origin?.type === 'port' ? formData.origin.port.code : undefined;
    const destinationPortCode =
      formData.destination?.type === 'port'
        ? formData.destination.port.code
        : undefined;

    const getCreationPayload = (): QuoteSubmissionPayload => {
      if (
        !formData.commodityCategory ||
        !formData.cargoOwner ||
        (isWtw ? false : !formData.containerModeId) ||
        !formData.coveragePackage
      ) {
        throw new Error('Please fill in all required fields');
      }

      return {
        distributor_id: formData.distributorId,

        transport_mode_code: formData.primaryMot,

        origin_port_code: originPortCode,
        destination_port_code: destinationPortCode,

        origin_place_provider_details: originPlace,
        destination_place_provider_details: destinationPlace,

        loading_place_provider_details: loadingPlace ?? undefined,
        loading_transport_mode_code: formData.placeOfLoading
          ? formData.placeOfLoadingMot
          : undefined,

        delivery_place_provider_details: deliveryPlace ?? undefined,
        delivery_transport_mode_code: formData.placeOfDelivery
          ? formData.placeOfDeliveryMot
          : undefined,

        commodity: {
          type_id: formData.commodityCategory.typeId,
          name: formData.commodityCategory.name,
          description: formData.commodityDescription,
          currency_code: formData.commodityCurrency,
          value: formData.commodityValue ? formData.commodityValue : undefined,
        },

        cargo_owner: {
          id: formData.cargoOwner.id,
          name: formData.cargoOwner.company_name,
        },

        container_mode_id: formData.containerModeId,

        freight_cost: formData.freightCost ? formData.freightCost : undefined,
        freight_cost_currency: formData.freightCost
          ? formData.freightCostCurrency
          : undefined,

        duty_cost: formData.dutyCost ? formData.dutyCost : undefined,
        duty_cost_currency: formData.dutyCost
          ? formData.dutyCostCurrency
          : undefined,

        external_reference: formData.externalReference,
        special_conditions: formData.specialConditions?.map((c) => ({
          value: c,
        })),
        coverage_package: formData.coveragePackage,
        letter_of_credit: formData.letterOfCredit,
      };
    };

    const getDuplicationPayload = (): QuoteDuplicationPayload => {
      invariant(quoteId, 'Original Quote ID is required for duplication');
      return {
        ...getCreationPayload(),
        original_quote_id: quoteId,
      };
    };

    const getUpdatePayload = (): QuoteUpdatePayload => {
      invariant(quoteId, 'Quote ID is required for update');
      invariant(user?.distributor.id, 'Distributor ID is required for update');

      return {
        ...getJourneyUpdatePayload(form),

        distributor_id: getUpdatedValue(
          formData.distributorId,
          formMeta,
          'distributorId',
        ),

        commodity: isAnyFieldDirty(form, [
          'commodityCategory',
          'commodityDescription',
          'commodityCurrency',
          'commodityValue',
        ])
          ? {
              type_id: getUpdatedValue(
                formData.commodityCategory?.typeId,
                formMeta,
                'commodityCategory',
              ),

              name: getUpdatedValue(
                formData.commodityCategory?.name,
                formMeta,
                'commodityCategory',
              ),

              description: getUpdatedValue(
                formData.commodityDescription,
                formMeta,
                'commodityDescription',
              ),

              currency_code: getUpdatedValue(
                formData.commodityCurrency,
                formMeta,
                'commodityCurrency',
              ),

              value: getUpdatedValue(
                formData.commodityValue,
                formMeta,
                'commodityValue',
              ),
            }
          : undefined,

        cargo_owner: isAnyFieldDirty(form, 'cargoOwner')
          ? {
              id: getUpdatedValue(
                formData.cargoOwner?.id,
                formMeta,
                'cargoOwner',
              ),

              name: getUpdatedValue(
                formData.cargoOwner?.company_name,
                formMeta,
                'cargoOwner',
              ),
            }
          : undefined,

        container_mode_id: getUpdatedValue(
          formData.containerModeId,
          formMeta,
          'containerModeId',
        ),

        freight_cost: getUpdatedValue(
          formData.freightCost,
          formMeta,
          'freightCost',
        ),

        freight_cost_currency: getUpdatedValue(
          formData.freightCostCurrency,
          formMeta,
          'freightCostCurrency',
        ),

        duty_cost: getUpdatedValue(formData.dutyCost, formMeta, 'dutyCost'),

        duty_cost_currency: getUpdatedValue(
          formData.dutyCostCurrency,
          formMeta,
          'dutyCostCurrency',
        ),

        external_reference: getUpdatedValue(
          formData.externalReference,
          formMeta,
          'externalReference',
        ),

        special_conditions:
          formData.specialConditions &&
          form.state.fieldMeta['specialConditions'].isDirty
            ? formData.specialConditions.map((c) => ({
                value: c,
              }))
            : undefined,

        coverage_package: getUpdatedValue(
          formData.coveragePackage,
          formMeta,
          'coveragePackage',
        ),

        letter_of_credit: getUpdatedValue(
          formData.letterOfCredit,
          formMeta,
          'letterOfCredit',
        ),
      };
    };

    if (isDuplicate) {
      return getDuplicationPayload();
    }

    if (isUpdate) {
      return getUpdatePayload();
    }

    return getCreationPayload();
  };

  return { isDuplicate, isUpdate, getPayload };
};
