import React from 'react';

interface Props {
  stops: number;
}

export const JourneyFromLine: React.FC<Props> = ({ stops }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={stops === 2 ? '250' : '65'}
      height="2"
      viewBox={`0 0 ${stops === 2 ? 250 : 66} 2`}
      fill="none"
    >
      <path
        d={stops === 2 ? 'M0 1H250' : 'M0 1H64.6667'}
        stroke="#E0E1E5"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
    </svg>
  );
};
