import { FaListCheck } from 'react-icons/fa6';
import { Typography } from '@breezeai-frontend/cargo-ui';

import { usePlatform } from '../../../../context/PlatformContext';
import { type Quote } from '../../../../model/Quote';
import { ExclusionReasons } from '../../../components/ExclusionReasons/ExclusionReasons';

export const QuoteManualReviewMessage = ({
  exclusionReasons,
}: {
  exclusionReasons?: Quote['exclusion_reasons'];
}) => {
  const { isWtw } = usePlatform();

  return (
    <div
      className="flex flex-col items-center justify-center min-h-[300px]"
      data-testid="quote-manual-review-message"
    >
      <FaListCheck size={32} className="mb-8 text-icons-primary" />
      <Typography level="h2" customStyles="mb-3">
        The quote needs to be processed manually, due to some exceptions.
      </Typography>

      <Typography>
        {isWtw
          ? 'We will get back to you by email as soon as possible'
          : 'We will get back to you within the next 2 hours.'}
      </Typography>

      {exclusionReasons?.length && (
        <div data-testid="exclusion-reasons-section" className="w-3/4 mt-6">
          <div className="h-[1px] w-full bg-system-grey-400 mb-6" />
          <ExclusionReasons reasons={exclusionReasons} />
        </div>
      )}
    </div>
  );
};
