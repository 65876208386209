import moment from 'moment';

import {
  ETD_MIN_DAYS_OFFSET_BREEZE,
  ETD_MIN_DAYS_OFFSET_WTW,
} from '../constants';

export const getDefaultMinMaxDates = (platform: 'breeze' | 'wtw') => {
  const minDateBreeze = moment()
    .startOf('day')
    .subtract(ETD_MIN_DAYS_OFFSET_BREEZE, 'days');

  const minDateWtw = moment()
    .startOf('day')
    .subtract(ETD_MIN_DAYS_OFFSET_WTW, 'days');

  const defaultMinDate = platform === 'breeze' ? minDateBreeze : minDateWtw;

  return {
    defaultMinDate,
    defaultMaxDate: moment().startOf('day').add(365, 'days'),
  };
};

export const getArrivalDateLimits = ({
  etd,
  terminationDate,
  platform = 'breeze',
}: {
  etd: moment.Moment | undefined;
  terminationDate: moment.Moment | undefined;
  platform: 'breeze' | 'wtw';
}) => {
  const { defaultMinDate, defaultMaxDate } = getDefaultMinMaxDates(platform);

  return {
    min: etd ?? defaultMinDate,
    max: terminationDate ?? defaultMaxDate,
  };
};

export const getDepartureDateLimits = ({
  eta,
  terminationDate,
  platform = 'breeze',
}: {
  eta: moment.Moment | undefined;
  terminationDate: moment.Moment | undefined;
  platform: 'breeze' | 'wtw';
}) => {
  const { defaultMinDate, defaultMaxDate } = getDefaultMinMaxDates(platform);
  return {
    min: defaultMinDate,
    max: eta ?? terminationDate ?? defaultMaxDate,
  };
};
