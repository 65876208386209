import { FaWarehouse } from 'react-icons/fa';
import { formatPrice, Typography } from '@breezeai-frontend/cargo-ui';
import { capitalize, words } from 'lodash';
import moment from 'moment';

import { CapacityMeter } from '../components/CapacityMeter';
import { type StorageLocationModel } from '../types';

export function Metrics({ data }: { data: StorageLocationModel }) {
  return (
    <div className="w-full flex flex-col space-y-8">
      <div className="w-full flex flex-row items-center justify-start text-text-secondary px-6">
        <Typography
          level="h5"
          customStyles="flex flex-row gap-2 pr-4 mr-4 border-r-2 border-solid border-system-grey-500 justify-center items-center"
        >
          <FaWarehouse className="size-4" />
          {words(data.type.replace('_', ' ')).map(capitalize).join(' ')}
        </Typography>
        <Typography
          level="h5"
          customStyles="pr-4 mr-4 border-r-2 border-solid border-system-grey-500 justify-center items-center"
        >
          {data.location_id}
        </Typography>
        <Typography
          level="h5"
          customStyles="pr-4 mr-4 border-r-2 border-solid border-system-grey-500 justify-center items-center"
        >
          {moment(data.created_date_time).format('DD/MM/YYYY')}
        </Typography>
        <Typography
          level="h5"
          customStyles="pr-4 mr-4 border-r-2 border-solid border-system-grey-500"
        >
          {data?.address.full_address}
        </Typography>
        {data?.insured_value_limit_currency && data?.insured_value_limit && (
          <Typography level="h5">
            {formatPrice({
              currency: data.insured_value_limit_currency.code,
              value: data.insured_value_limit,
            })}
          </Typography>
        )}
      </div>
      <div className="w-full grid grid-cols-8 grid-row-1 rounded-md bg-system-grey-white p-6">
        <div className="col-span-2 flex flex-col justify-center gap-y-1">
          <Typography level="subtext" customStyles="text-text-secondary">
            Estimated TIV on risk
          </Typography>
          {data?.active_total_insured_value_currency &&
            data?.active_total_insured_value && (
              <Typography level="h5" customStyles="text-text-secondary gap-y-1">
                {formatPrice({
                  currency: data.active_total_insured_value_currency.code,
                  value: data.active_total_insured_value,
                })}
              </Typography>
            )}
        </div>
        <div className="col-span-2 flex flex-col justify-center gap-y-1">
          <Typography level="subtext" customStyles="text-text-secondary">
            Capacity Used
          </Typography>
          <CapacityMeter value={data.capacity ? data.capacity * 100 : 0} />
        </div>
      </div>
    </div>
  );
}
