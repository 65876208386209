import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ErrorDialog, type FileError } from '@breeze-ai/ui-library';
import { FileUpload, Typography } from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import { useForm } from '@tanstack/react-form';
import moment from 'moment';

import { queryClient } from '../../../../providers/ReactQueryProvider';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import { FileList } from '../../../components/FileList/FileList';
import {
  type StorageCreationModalRouteParams,
  StorageLocationFileTypeEnum,
  SupportedFileTypes,
} from '../../types';
import { uploadStorageLocationFile } from '../../utils';

const fileRules = {
  maxSizeMB: 15,
  allowedTypes: Object.values(SupportedFileTypes),
};

type StorageFileUploadFormProps = {
  surveyReport: File[];
  otherFiles: File[];
  surveyReportExpiryDate?: ISODate;
};

export function StorageFileUploadForm({ isUpdate }: { isUpdate: boolean }) {
  const [isLoading, setIsLoading] = useState(false);

  const [surveyReportFilePickerErrors, setSurveyReportFilePickerErrors] =
    useState<FileError[] | undefined>();
  const [otherFilePickerErrors, setOtherFilePickerErrors] = useState<
    FileError[] | undefined
  >();

  const [error, setError] = useState<boolean>();
  const { policyId, storageLocationId } =
    useParams<StorageCreationModalRouteParams>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const updateTarget = searchParams.get('type');

  const form = useForm<StorageFileUploadFormProps>({
    defaultValues: {
      surveyReport: [],
      otherFiles: [],
      surveyReportExpiryDate: '',
    },
    onSubmit: async ({ value }) => {
      if (!policyId) return;

      try {
        setIsLoading(true);
        if (value.surveyReport.length >= 1) {
          await Promise.all(
            value.surveyReport?.map((file) =>
              uploadStorageLocationFile({
                policyId,
                file,
                documentType: StorageLocationFileTypeEnum.SURVEY_REPORT,
                surveyReportExpiryDate: value.surveyReportExpiryDate
                  ? moment(value.surveyReportExpiryDate).format(
                      'YYYY-MM-DD HH:mm:ss',
                    )
                  : undefined,
              }),
            ),
          );
        }
        if (value.otherFiles.length >= 1) {
          await Promise.all(
            value.otherFiles?.map((file) =>
              uploadStorageLocationFile({
                policyId,
                file,
                documentType: StorageLocationFileTypeEnum.OTHER,
              }),
            ),
          );
        }
        queryClient.invalidateQueries({
          queryKey: ['storageLocation', String(policyId)],
        });
        navigate(`/storage/details/${storageLocationId}/${policyId}`, {
          replace: true,
        });
      } catch (e) {
        reportException(e);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const surveyReport = (
    <div className="space-y-3">
      <Typography level="h5">Survey Report</Typography>
      <form.Field
        name="surveyReport"
        children={(field) => (
          <FileUpload
            disabled={field.state.value.length >= 1}
            name={field.name}
            id={field.name}
            // Only single file upload is allowed for survey report
            multiple={false}
            title="Drag your files here"
            message="Or click here to select from your computer"
            rules={fileRules}
            renderRules={({ allowedTypes, maxSizeMB }) => (
              <Typography level="h5" variant="input">
                {allowedTypes?.join(', ').toUpperCase()} files up to {maxSizeMB}{' '}
                MB are allowed
              </Typography>
            )}
            filesPreview={{
              external: true,

              render: (files, onRemoveFile) => (
                <div className="mt-6 flex flex-col gap-y-4">
                  <Typography level="h5">Shared files</Typography>
                  <FileList
                    files={files}
                    errors={
                      surveyReportFilePickerErrors?.map((error) => ({
                        [error.name]: error.reason,
                      }))[0]
                    }
                    onChangeExpiryDate={(date) => {
                      field.form.setFieldValue('surveyReportExpiryDate', date, {
                        touch: true,
                      });
                    }}
                    onRemove={(file) => {
                      onRemoveFile(file);
                      field.form.setFieldValue(
                        'surveyReportExpiryDate',
                        undefined,
                        { touch: true },
                      );
                      field.handleChange(
                        field.state.value.filter((f) => f !== file),
                      );
                    }}
                  />
                </div>
              ),
            }}
            value={field.state.value}
            onChange={field.handleChange}
            onError={setSurveyReportFilePickerErrors}
          />
        )}
      />
    </div>
  );

  const otherFiles = (
    <div className="space-y-3">
      <Typography level="h5">Other Documents</Typography>
      <form.Field
        name="otherFiles"
        children={(field) => (
          <FileUpload
            disabled={field.state.value.length >= 1}
            name={field.name}
            id={field.name}
            value={field.state.value}
            title="Drag your files here"
            message="Or click here to select from your computer"
            rules={fileRules}
            renderRules={({ allowedTypes, maxSizeMB }) => (
              <Typography level="h5" variant="input">
                {allowedTypes?.join(', ').toUpperCase()} files up to {maxSizeMB}{' '}
                MB are allowed
              </Typography>
            )}
            filesPreview={{
              external: true,
              render: (files, onRemoveFile) => (
                <div className="mt-6 flex flex-col gap-y-4">
                  <Typography level="h5">Shared files</Typography>
                  <FileList
                    files={files}
                    onRemove={(file) => {
                      onRemoveFile(file);
                      field.handleChange(
                        field.state.value.filter((f) => f !== file),
                      );
                    }}
                    errors={
                      otherFilePickerErrors?.map((error) => ({
                        [error.name]: error.reason,
                      }))[0]
                    }
                  />
                </div>
              ),
            }}
            onChange={field.handleChange}
            onError={setOtherFilePickerErrors}
          />
        )}
      />
    </div>
  );

  let body: JSX.Element;
  switch (updateTarget) {
    case StorageLocationFileTypeEnum.SURVEY_REPORT:
      body = surveyReport;
      break;
    case StorageLocationFileTypeEnum.OTHER:
      body = otherFiles;
      break;
    default:
      body = (
        <div className="space-y-6">
          {surveyReport}
          {otherFiles}
        </div>
      );
      break;
  }

  return (
    <div className="h-full">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
        className="space-y-6 flex flex-col h-full justify-between"
      >
        {body}
        <form.Subscribe
          selector={(state) => [state.isTouched]}
          children={([_isTouched, _isValid]) => (
            <div className="flex gap-4 w-full justify-end">
              <Button
                variant="ghost"
                size="large"
                type="reset"
                width="auto"
                label={isUpdate ? 'Cancel Changes' : 'Cancel'}
                onPress={() => navigate('/storage')}
              />
              {isUpdate ? (
                <Button
                  size="large"
                  width="auto"
                  type="submit"
                  loadingText="Updating Storage Location Files..."
                  label="Save Changes"
                  isLoading={isLoading}
                />
              ) : (
                <Button
                  size="large"
                  width="auto"
                  type="submit"
                  loadingText="Submit"
                  label="Done"
                  isLoading={isLoading}
                />
              )}
            </div>
          )}
        />
      </form>
      <ErrorDialog visible={error} onConfirm={() => setError(false)} />
    </div>
  );
}
