import {
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { type Claim } from '../../../model/Claim';
import { type Policy } from '../../../model/Policy';
import { bffServiceUrl } from '../../netconfig';
import { normalizeClaimResponse } from '../claims/normalizers';
import { get } from '../utils';
import { normalizePolicyResponse } from './normalizers';
import { type GetPolicyClaimsResponse, type PolicyModel } from './types';

// TODO extract query options into own object rather than using custom hook wrapper
export const usePolicy = (
  { policyId }: { policyId?: string },
  options?: UseQueryOptions<PolicyModel, unknown, Policy, Array<string>>,
) => {
  return useQuery({
    queryKey: ['policy', policyId!],
    queryFn: () => get<PolicyModel>(`${bffServiceUrl}/policies/${policyId}`),
    select: normalizePolicyResponse,
    enabled: !!policyId,
    ...options,
  });
};

export const usePolicySuspense = ({ policyId }: { policyId?: string }) => {
  return useSuspenseQuery({
    queryKey: ['policy', policyId!],
    // TODO: remove the null check and use component composition as described: https://github.com/TanStack/query/discussions/6206#discussioncomment-7343078
    queryFn: () =>
      policyId
        ? get<PolicyModel>(`${bffServiceUrl}/policies/${policyId}`)
        : null,
    select: (data) => data && normalizePolicyResponse(data),
  });
};

export const usePolicyClaims = ({
  policyId,
  options,
}: {
  policyId?: string;
  options?: UseQueryOptions<
    GetPolicyClaimsResponse,
    unknown,
    Claim[],
    Array<string>
  >;
}) => {
  return useQuery({
    queryKey: ['policyClaims', String(policyId)],

    queryFn: () =>
      get<GetPolicyClaimsResponse>(
        `${bffServiceUrl}/policies/${policyId}/claims`,
      ),

    select: (data) => data.claims.map(normalizeClaimResponse),
    enabled: !!policyId,
    ...options,
  });
};

export const usePolicyClaimsSuspense = ({
  policyId,
}: {
  policyId?: string;
}) => {
  return useSuspenseQuery({
    queryKey: ['policyClaims', String(policyId)],
    queryFn: () =>
      // TODO: remove the null check and use component composition as described: https://github.com/TanStack/query/discussions/6206#discussioncomment-7343078
      policyId
        ? get<GetPolicyClaimsResponse>(
            `${bffServiceUrl}/policies/${policyId}/claims`,
          )
        : null,

    select: (data) => data && data.claims.map(normalizeClaimResponse),
  });
};
