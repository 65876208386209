import { Button } from '@breezeai-frontend/cargo-ui';
import { type FormApi } from '@tanstack/react-form';
import { type zodValidator } from '@tanstack/zod-form-adapter';

import { useFormLogic } from '../useFormLogic';
import { useModalStore } from '../useModalStore';
import { type CertificateFormProps } from './types';

export function SubmissionButtons({
  form,
  isUnderReview,
  isSubmitting,
}: {
  form: FormApi<CertificateFormProps, typeof zodValidator>;
  isUnderReview: boolean;
  isSubmitting: boolean;
}) {
  const { isUpdate, isDuplicate, certificateId } = useFormLogic();
  const { setIsCancelChangesModalOpen, setIsConfirmChangesModalOpen } =
    useModalStore();
  const newCertificateUnderReview = isUnderReview && !certificateId;
  return (
    <form.Subscribe
      selector={(state) => [state.isDirty]}
      children={([isDirty]) => {
        const isUpdatable = isUpdate && isDirty;
        return isUpdate ? (
          <>
            <Button
              variant="ghost"
              size="large"
              width="auto"
              onPress={() => setIsCancelChangesModalOpen(true)}
              isLoading={isSubmitting}
              isDisabled={!isUpdatable}
              label="Cancel Changes"
            />
            <Button
              data-testid="save-changes-button"
              size="large"
              width="auto"
              onPress={() =>
                form
                  .validateAllFields('submit')
                  .then(
                    (errors) =>
                      errors.length === 0 && setIsConfirmChangesModalOpen(true),
                  )
              }
              isDisabled={!isUpdatable}
              isLoading={isSubmitting}
              loadingText="Updating Certificate..."
              label="Save Changes"
            />
          </>
        ) : (
          <Button
            data-testid="create-certificate-button"
            size="large"
            width="auto"
            type="submit"
            isDisabled={isSubmitting || newCertificateUnderReview}
            isLoading={isSubmitting}
            loadingText="Creating Certificate..."
            label={isDuplicate ? 'Duplicate Certificate' : 'Create Certificate'}
          />
        );
      }}
    />
  );
}
