import {
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { type ErrorResponse } from '../../../network/apis/types';
import { get } from '../../../network/apis/utils';
import { bffServiceUrl } from '../../../network/netconfig';
import { type StorageLocationModel } from '../types';

export const useStorageLocation = ({
  options,
  policyId,
}: {
  policyId?: string;
  options?: UseQueryOptions<
    StorageLocationModel,
    ErrorResponse,
    StorageLocationModel
  >;
}) => {
  const key = ['storageLocation', policyId];

  return useQuery({
    enabled: !!policyId,
    queryKey: key,
    queryFn: () =>
      get<StorageLocationModel>(
        `${bffServiceUrl}/bff-storage-locations/${policyId}`,
      ),
    ...options,
  });
};

export const useStorageLocationSuspense = ({
  policyId,
}: {
  policyId?: string;
  options?: UseQueryOptions<
    StorageLocationModel,
    ErrorResponse,
    StorageLocationModel
  >;
}) => {
  const key = ['storageLocation', policyId];

  return useSuspenseQuery({
    queryKey: key,
    // TODO: remove the null check and use component composition as described: https://github.com/TanStack/query/discussions/6206#discussioncomment-7343078
    queryFn: () =>
      policyId
        ? get<StorageLocationModel>(
            `${bffServiceUrl}/bff-storage-locations/${policyId}`,
          )
        : null,
  });
};
