import { forwardRef, type ReactNode } from 'react';
import { clsxMerge } from '@breezeai-frontend/cargo-ui';

type PolicyFlowStepProps = {
  children: ReactNode;
  visible?: boolean;
  scrollMargin?: boolean;
  className?: string;
};

export const PolicyFlowStep = forwardRef<HTMLDivElement, PolicyFlowStepProps>(
  ({ children, visible = true, className }, ref) => {
    if (!visible) {
      return null;
    }

    return (
      <div ref={ref} className={clsxMerge('scroll-my-20 h-auto', className)}>
        {children}
      </div>
    );
  },
);
