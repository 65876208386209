import { usePolicySuspense } from '../../../../network/apis/policies/hooks';
import { useRouteParams } from '../../../../router/router-hooks';
import { PageSection } from '../../../components/PageSection/PageSection';
import { PremiumBreakdown } from '../../../components/PremiumBreakdown/PremiumBreakdown';
import { type PolicyRouteParams } from '../PolicyDetailsPage';

export function PolicyPriceBreakdown() {
  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;
  const { data: policy } = usePolicySuspense({ policyId });

  if (!policy?.quote) {
    return null;
  }

  const {
    premium_value,
    premium_currency,
    premium_rate,
    tax,
    exchange_rate,
    converted_customer_premium_value,
    converted_tax_amount,
    distributor,
  } = policy.quote;

  if (!premium_value || !premium_currency) {
    return null;
  }

  return (
    <PageSection
      title="Price details"
      className="flex flex-col shrink grow-0 basis-[300px]"
    >
      <PremiumBreakdown
        premium_value={premium_value}
        premium_currency={premium_currency}
        premium_rate={premium_rate}
        tax={tax}
        exchange_rate={exchange_rate}
        converted_customer_premium_value={converted_customer_premium_value}
        converted_tax_amount={converted_tax_amount}
        hide_tax_info={distributor?.hide_tax_info}
        componentProps={{
          components: { currency: { level: 'base' } },
          total: { title: { level: 'h5' }, currency: { level: 'h2' } },
        }}
      />
    </PageSection>
  );
}
