import { Dialog, Loader } from '@breeze-ai/ui-library';

import { usePolicyFlowContext } from '../../hooks/context-hooks';
import styles from './PolicyFlowLoadingDialog.module.scss';

type PolicyFlowLoadingDialogProps = {
  visible?: boolean;
};

export const PolicyFlowLoadingDialog = ({
  visible,
}: PolicyFlowLoadingDialogProps) => {
  const { error } = usePolicyFlowContext();

  if (error) {
    return null;
  }

  return (
    <Dialog
      role="loading-dialog"
      data-testid="policy-flow-loading-dialog"
      visible={visible}
      closable={false}
      className={styles.dialogWrapper}
      width={480}
    >
      <Dialog.Illustration slot="illustration" width="fit-content">
        <Loader type="bars" />
      </Dialog.Illustration>
      <Dialog.Title slot="title">Alright!</Dialog.Title>
      <Dialog.Content slot="content">
        We’re working on it. Just a few seconds...
      </Dialog.Content>
    </Dialog>
  );
};
