import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocale } from '@breeze-ai/ui-library';
import { CargoProvider } from '@breezeai-frontend/cargo-ui';

import { Footer } from '../../../../../packages/cargo-ui/src/components/Footer/Footer';
import { useUserSetting } from '../../context/auth/auth-hooks';
import { usePlatform } from '../../context/PlatformContext';
import { Sidebar } from '../../ui/sidebar/Sidebar';
import { SupportDialog } from '../../ui/support-dialog/SupportDialog';
import { initialLocale, setAppLocale } from '../../utils/locale';

const PRIVACY_POLICY_URL = import.meta.env.VITE_APP_PRIVACY_POLICY_URL;
const TERMS_OF_USE_URL = import.meta.env.VITE_APP_TERMS_OF_USE_URL;

export const AuthorizedPlatform = () => {
  const userLocale = useUserSetting<string>('locale');
  const { setLocale: setComponentsLocale } = useLocale();
  const navigate = useNavigate();
  const { isWtw, platform } = usePlatform();

  // Update the locale according to the
  // logged-in user and reset it on log out.
  useEffect(() => {
    setAppLocale(userLocale);
    setComponentsLocale?.(userLocale);

    return () => {
      setAppLocale(initialLocale);
      setComponentsLocale?.(initialLocale);
    };
  }, [setComponentsLocale, userLocale]);

  const footerLinks = [
    {
      label: 'Privacy Policy',
      url: PRIVACY_POLICY_URL,
    },
    {
      label: isWtw ? 'Cookie Notice' : 'Terms of Use',
      url: TERMS_OF_USE_URL,
    },
  ];

  return (
    <CargoProvider
      theme={platform}
      navigate={navigate}
      userLocaleOverride={userLocale}
    >
      <div className="flex h-dvh w-dvw bg-surfaces-secondary">
        <Sidebar />
        <div
          id="main-breeze-app-frame"
          className="flex flex-col w-full overflow-auto"
        >
          <div className="w-full h-full px-10 min-w-[400px] flex flex-col justify-between overflow-auto">
            <Outlet />
          </div>
          <Footer links={footerLinks} />
        </div>
      </div>
      <SupportDialog />
    </CargoProvider>
  );
};
