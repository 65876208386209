import {
  type ChangeEvent,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Icon, SearchInput, Typography } from '@breeze-ai/ui-library';
import { Button, clsxMerge } from '@breezeai-frontend/cargo-ui';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { debounce } from 'lodash';

import { TriggerButton } from '../TriggerButton/TriggerButton';
import styles from './MultiSelectRadioDropdown.module.scss';

export type Option = {
  label: string | ReactNode;
  value: string;
  labelHtmlFor?: string;
  prefix?: ReactNode;
  error?: boolean;
};

const RadioGroupItem = ({
  value,
  label,
  prefix,
  labelHtmlFor,
  selectionStyling,
  handleOnSelect,
  selected,
  setSelected,
}: {
  value: string;
  label: string | ReactNode;
  labelHtmlFor?: string;
  prefix?: ReactNode;
  selectionStyling: (optionValue: string) => string;
  handleOnSelect: (option: string) => void;
  selected: string[];
  setSelected: (selected: string[]) => void;
}) => {
  return (
    <button
      key={value}
      className={selectionStyling(value)}
      onClick={() => {
        setSelected([...selected, value]);
        handleOnSelect(value);
      }}
    >
      <DropdownMenu.CheckboxItem
        onSelect={(e) => {
          e.preventDefault();
          handleOnSelect(value);
        }}
        className={styles.RadioGroupItem}
        checked={selected.includes(value)}
        id={value}
        data-testid={`radio-item-${value}`}
      >
        <DropdownMenu.ItemIndicator
          className={clsxMerge(
            styles.radioGroupIndicator,
            'bg-buttons-primary-bg-default',
          )}
        >
          <Icon name="check" size="xs" icon="check" />
        </DropdownMenu.ItemIndicator>
      </DropdownMenu.CheckboxItem>
      {prefix && <div className={styles.avatar}>{prefix}</div>}
      <label htmlFor={labelHtmlFor}>
        <Typography>{label}</Typography>
      </label>
    </button>
  );
};

export const MultiSelectRadioDropdown = ({
  nonAppliedOptions,
  onSubmit,
  onReset,
  name,
  label,
  dropdownIcon,
  placeholder,
  isError,
  onSearch,
  isLoading,
  setSelected,
  selected,
  optionsCount,
  appliedOptions,
  triggerTestId,
  contentTestId,
}: {
  selected: string[];
  setSelected: (selected: string[]) => void;
  nonAppliedOptions: Option[];
  onSubmit: (selected: string[]) => void;
  onReset: () => void;
  name: string;
  label: string;
  dropdownIcon?: IconName | ReactNode;
  placeholder?: string;
  isError?: boolean;
  onSearch?: (query: string) => void;
  isLoading?: boolean;
  optionsCount?: number;
  appliedOptions: Option[];
  triggerTestId?: string;
  contentTestId?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectionStyling = (optionValue: string) =>
    clsxMerge(
      styles.radioGroupItemContainer,
      'hover:bg-dropdown-row-bg-hover',
      {
        ['bg-dropdown-row-bg-selected hover:bg-dropdown-row-bg-selected']:
          selected.includes(optionValue),
      },
    );

  const handleOnSelect = useCallback(
    (option: string) => {
      if (selected.includes(option)) {
        setSelected(selected.filter((item) => item !== option));
      } else {
        setSelected([...selected, option]);
      }
    },
    [selected, setSelected],
  );

  const handleOnInput = (e: ChangeEvent<HTMLInputElement>) =>
    debounce(() => onSearch && onSearch(e.target.value), 1000)();

  const isSelectedAndAppliedSame = useMemo(() => {
    return (
      selected.length === appliedOptions.length &&
      selected.every((value) =>
        appliedOptions.map((o) => o.value).includes(value),
      )
    );
  }, [selected, appliedOptions]);

  if (isError) return null;

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger
        asChild
        disabled={isLoading}
        data-testid={triggerTestId}
      >
        <TriggerButton
          selectedCount={optionsCount}
          name={name}
          label={label}
          prefixIcon={dropdownIcon}
          suffixIcon="chevron-down"
          isLoading={isLoading}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          data-testid={contentTestId}
          sideOffset={5}
          align="start"
          className={styles.dropdownMenuContent}
        >
          {onSearch && (
            <SearchInput
              placeholder={placeholder ?? 'Search'}
              className={styles.searchInput}
              onChange={handleOnInput}
              onClear={() => onSearch('')}
            />
          )}
          <DropdownMenu.RadioGroup
            onValueChange={(value) => setSelected([...selected, value])}
            className={styles.radioGroupRoot}
            aria-label="Select Option"
            data-testid="radio-group"
          >
            {appliedOptions.map(({ value, label, prefix, labelHtmlFor }) => {
              return (
                <RadioGroupItem
                  key={value}
                  value={value}
                  label={label}
                  labelHtmlFor={
                    typeof label === 'string' ? label : labelHtmlFor
                  }
                  prefix={prefix}
                  selectionStyling={selectionStyling}
                  handleOnSelect={handleOnSelect}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            })}
            {nonAppliedOptions.length > 0 && appliedOptions.length > 0 && (
              <hr className={styles.divider} />
            )}
            {nonAppliedOptions.map(({ value, label, prefix, labelHtmlFor }) => {
              return (
                <RadioGroupItem
                  key={value}
                  value={value}
                  label={label}
                  labelHtmlFor={
                    typeof label === 'string' ? label : labelHtmlFor
                  }
                  prefix={prefix}
                  selectionStyling={selectionStyling}
                  handleOnSelect={handleOnSelect}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            })}
          </DropdownMenu.RadioGroup>
          <hr className={styles.divider} />
          <footer className={styles.footer}>
            <Button
              data-testid="reset-button"
              isDisabled={appliedOptions.length === 0}
              variant="ghost"
              width="auto"
              size="small"
              onPress={() => {
                setSelected([]);
                onReset();
                setIsOpen(false);
              }}
              label="Reset"
            />
            <Button
              isDisabled={isSelectedAndAppliedSame}
              size="small"
              variant="ghost"
              width="auto"
              type="submit"
              onPress={() => {
                onSubmit(selected);
                setIsOpen(false);
              }}
              data-testid="apply-button"
              label="Apply"
            />
          </footer>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
