import { createContext, type ReactNode, useReducer } from 'react';

import { FlowSteps } from '../constants';
import { PolicyFlowContextReducer } from './PolicyFlowContextReducer';
import { type PolicyFlowContextType, type PolicyFlowState } from './types';

const initialState: PolicyFlowState = {
  step: FlowSteps.INIT,
  forms: {
    errors: [],
  },
};

export const PolicyFlowContext = createContext<PolicyFlowContextType>({
  state: initialState,
  dispatch: () => undefined,
});

export const PolicyFlowContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(PolicyFlowContextReducer, initialState);

  return (
    <PolicyFlowContext.Provider value={{ state, dispatch }}>
      {children}
    </PolicyFlowContext.Provider>
  );
};
