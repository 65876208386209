import React from 'react';

interface Props {
  stops: number;
}

export const JourneyToLine: React.FC<Props> = ({ stops }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={stops === 2 ? '250' : '65'}
      height="16"
      viewBox={`0 0 ${stops === 2 ? 250 : 66} 16`}
      fill="none"
    >
      <path
        d={
          stops === 2
            ? 'M250.707 8.70711C251.098 8.31658 251.098 7.68342 250.707 7.29289L244.343 0.928932C243.953 0.538408 243.319 0.538408 242.929 0.928932C242.538 1.31946 242.538 1.95262 242.929 2.34315L248.586 8L242.929 13.6569C242.538 14.0474 242.538 14.6805 242.929 15.0711C243.319 15.4616 243.953 15.4616 244.343 15.0711L250.707 8.70711ZM0 9H2.01613V7H0V9ZM6.04839 9H10.0806V7H6.04839V9ZM14.1129 9H18.1452V7H14.1129V9ZM22.1774 9H26.2097V7H22.1774V9ZM30.2419 9H34.2742V7H30.2419V9ZM38.3064 9H42.3387V7H38.3064V9ZM46.371 9H50.4032V7H46.371V9ZM54.4355 9H58.4677V7H54.4355V9ZM62.5 9H66.5322V7H62.5V9ZM70.5645 9H74.5968V7H70.5645V9ZM78.629 9H82.6613V7H78.629V9ZM86.6935 9H90.7258V7H86.6935V9ZM94.7581 9H98.7903V7H94.7581V9ZM102.823 9H106.855V7H102.823V9ZM110.887 9H114.919V7H110.887V9ZM118.952 9H122.984V7H118.952V9ZM127.016 9H131.048V7H127.016V9ZM135.081 9H139.113V7H135.081V9ZM143.145 9H147.177V7H143.145V9ZM151.21 9H155.242V7H151.21V9ZM159.274 9H163.306V7H159.274V9ZM167.339 9H171.371V7H167.339V9ZM175.403 9H179.435V7H175.403V9ZM183.468 9H187.5V7H183.468V9ZM191.532 9H195.564V7H191.532V9ZM199.597 9H203.629V7H199.597V9ZM207.661 9H211.694V7H207.661V9ZM215.726 9H219.758V7H215.726V9ZM223.79 9H227.823V7H223.79V9ZM231.855 9H235.887V7H231.855V9ZM239.919 9H243.952V7H239.919V9ZM247.984 9H250V7H247.984V9Z'
            : 'M65.3738 8.70711C65.7643 8.31658 65.7643 7.68342 65.3738 7.29289L59.0098 0.928932C58.6193 0.538408 57.9861 0.538408 57.5956 0.928932C57.2051 1.31946 57.2051 1.95262 57.5956 2.34315L63.2525 8L57.5956 13.6569C57.2051 14.0474 57.2051 14.6805 57.5956 15.0711C57.9861 15.4616 58.6193 15.4616 59.0098 15.0711L65.3738 8.70711ZM0 9H2.02083V7H0V9ZM6.0625 9H10.1042V7H6.0625V9ZM14.1458 9H18.1875V7H14.1458V9ZM22.2292 9H26.2708V7H22.2292V9ZM30.3125 9H34.3542V7H30.3125V9ZM38.3958 9H42.4375V7H38.3958V9ZM46.4792 9H50.5208V7H46.4792V9ZM54.5625 9H58.6042V7H54.5625V9ZM62.6458 9H64.6667V7H62.6458V9Z'
        }
        fill="#E0E1E5"
      />
    </svg>
  );
};
