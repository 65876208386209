import { useEffect, useMemo, useState } from 'react';

import { useUser } from '../../../../../../context/auth/auth-hooks';
import {
  FilterableFields,
  FilterOperators,
} from '../../../../../../network/apis/types';
import { post } from '../../../../../../network/apis/utils';
import { bffServiceUrl } from '../../../../../../network/netconfig';
import { useFilterStore } from '../../../../../components/TableFilters';
import { type StorageLocationAddress } from '../../../../types';
import { StorageLocationCityCountryDropdown } from './StorageLocationCityCountryDropdown';

interface StorageLocationCountryCityResponse {
  addresses: StorageLocationAddress[];
}

export const getStorageLocationCountryCityOptions = async (
  distributor_id: number,
): Promise<StorageLocationCountryCityResponse> => {
  const { data } = await post<StorageLocationCountryCityResponse>(
    `${bffServiceUrl}/bff-storage-locations/get-addresses`,
    {
      distributor_id,
      paginate: false,
    },
  );

  return data;
};

export function StorageLocationCountryCityFilter() {
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [cityCountryOptions, setCityCountryOptions] = useState<
    StorageLocationAddress[]
  >([]);
  const user = useUser();

  const filteredCityCountryOptions = useMemo(
    () =>
      cityCountryOptions.filter(
        (option) =>
          option.country.toLowerCase().includes(query.toLowerCase()) ||
          option.city.toLowerCase().includes(query.toLowerCase()),
      ),
    [cityCountryOptions, query],
  );

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    // @pitops: why did you deside not to use useStorageLocationAddressSuspense here?
    getStorageLocationCountryCityOptions(user.distributor.id)
      .then((data) => {
        setCityCountryOptions(data.addresses);
      })
      .catch(() => {
        setError('Error fetching storage locations');
      })
      .finally(() => setLoading(false));
  }, [user]);

  const {
    setSelectedStorageLocation,
    selectedStorageLocation,
    updateFilter,
    removeFilter,
    filters,
  } = useFilterStore((state) => ({
    setSelectedStorageLocation: state.setSelectedStorageLocation,
    selectedStorageLocation: state.selectedStorageLocation,
    updateFilter: state.updateFilter,
    removeFilter: state.removeFilter,
    filters: state.filters.filter(
      (filter) =>
        filter.field === FilterableFields.CITY ||
        filter.field === FilterableFields.COUNTRY_NAME,
    ),
  }));

  const onSubmit = () => {
    const location = cityCountryOptions.find((option) => {
      // due to migrated data not having placeId we need to be more elaborate in finding out the location
      const [city, country, placeId] = selectedStorageLocation[0].split('__');
      return (
        (option.place_id ?? '') === placeId &&
        option.city === city &&
        option.country === country
      );
    });
    if (!location) return;
    setQuery('');

    updateFilter([
      {
        field: FilterableFields.CITY,
        operator: FilterOperators.EQUALS,
        values: [location.city],
      },
      {
        field: FilterableFields.COUNTRY_NAME,
        operator: FilterOperators.EQUALS,
        values: [location.country],
      },
    ]);
  };

  const onReset = () => {
    setQuery('');
    removeFilter(FilterableFields.CITY);
    removeFilter(FilterableFields.COUNTRY_NAME);
  };

  const appliedOptions = useMemo(
    () =>
      filteredCityCountryOptions?.filter(
        (option) =>
          filters.find((filter) => filter.values[0] === option.country) ||
          filters.find((filter) => filter.values[0] === option.city),
      ) ?? [],
    [filteredCityCountryOptions, filters],
  );

  return (
    <StorageLocationCityCountryDropdown
      appliedOptions={appliedOptions}
      options={filteredCityCountryOptions}
      optionsCount={filters?.length ? 1 : 0}
      selected={selectedStorageLocation}
      setSelected={setSelectedStorageLocation}
      onSearch={setQuery}
      name="storageLocation"
      label="Country, City"
      dropdownIcon="location-dot"
      onSubmit={onSubmit}
      onReset={onReset}
      placeholder="Search country or city"
      isError={Boolean(error)}
      isLoading={loading}
      triggerTestId="storage-location-filter-trigger"
      contentTestId="storage-location-filter-content"
    />
  );
}
