import { CiCircleQuestion } from 'react-icons/ci';
import { FaChevronLeft, FaCopy, FaPen } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '@breeze-ai/ui-library';
import {
  Button,
  LinkButton,
  Tooltip,
  TooltipTriggerWrapper,
} from '@breezeai-frontend/cargo-ui';

import { usePlatform } from '../../../../context/PlatformContext';
import { QuoteStatusEnum } from '../../../../network/apis/quotes/types';
import { useCertificate } from '../../certificateApiHooks';
import { type CertificatesRouteParams } from '../../types';

export default function Title() {
  const { certificateId, policyId } = useParams<CertificatesRouteParams>();
  const { data: certificate, isLoading } = useCertificate({
    policyId,
  });
  const isEditDisabled = certificate?.associated_with_open_claim || isLoading;

  const { isWtw } = usePlatform();
  const isArchived = certificate?.quote?.status === QuoteStatusEnum.ARCHIVED;

  return (
    <div className="flex flex-row sm:items-center sm:justify-between text-nowrap gap-2 w-full">
      <div className="flex flex-row">
        <Link to="/certificates" className="flex justify-center items-center">
          <FaChevronLeft className="size-5 mr-2" />
        </Link>
        {/* External certificate id */}
        <Typography level="h2">Certificate {certificateId}</Typography>
      </div>

      <div className="flex sm:flex-row flex-col items-center gap-2">
        <LinkButton
          variant="secondary"
          label="Duplicate"
          leftIcon={<FaCopy />}
          width="auto"
          href={`/certificates/duplicate/${certificateId}/${policyId}`}
        />
        <LinkButton
          variant="secondary"
          label="Edit"
          leftIcon={<FaPen />}
          width="auto"
          isDisabled={isArchived || isEditDisabled}
          href={`/certificates/update/${certificateId}/${policyId}`}
        />

        {isWtw && isArchived && (
          <TooltipTriggerWrapper delay={0}>
            <Button variant="icon">
              <CiCircleQuestion size={24} />
            </Button>
            <Tooltip placement="top">Primary assured is archived.</Tooltip>
          </TooltipTriggerWrapper>
        )}
      </div>
    </div>
  );
}
