import { Tooltip } from '@breeze-ai/ui-library';
import { clsxMerge, Typography } from '@breezeai-frontend/cargo-ui';

import { isPlace } from '../../../model/Place';
import {
  type ConveyanceType,
  type ShipmentLocationType,
} from '../../../model/Shipment';
import { CONVEYANCE_TYPE_ICONS } from '../../constants';
import { JourneyFromLine } from './JourneyFromLine';
import { JourneyToLine } from './JourneyToLine';

export interface JourneyStop {
  label: string;
  location: ShipmentLocationType;
  transportMode: ConveyanceType | null;
  isPrimaryTransport?: boolean;
}

type JourneySummaryProps = {
  stops: JourneyStop[];
  className?: string;
};

export const JourneySummary = ({ stops, className }: JourneySummaryProps) => {
  function getLocation(stop: JourneyStop) {
    return isPlace(stop.location)
      ? stop.location.display_name
      : stop.location.code.toUpperCase();
  }
  return (
    <div className="mb-10" data-testid="journey-summary">
      <div className={clsxMerge('flex gap-2', className)}>
        {stops.map((stop, index) => {
          if (stops.length === index + 1) return null;
          const Icon = stop.transportMode
            ? CONVEYANCE_TYPE_ICONS[stop.transportMode]
            : null;
          const nextStopIsLast = stops.length - 1 === index + 1;
          const nextStop = stops[index + 1];
          const location = getLocation(stop);

          const nextLocation = getLocation(nextStop);

          return (
            <div
              key={index}
              className="relative flex items-center justify-center w-full flex-1"
            >
              <div
                className={
                  'flex flex-col items-center absolute left-1.5 transform -translate-x-1/2 top-8'
                }
              >
                <div className="flex flex-col items-center">
                  <Typography color="secondary" level="caption">
                    {stop.label}
                  </Typography>
                  <Tooltip content={stop.location.name} placement="bottom">
                    <Typography color="primary" text="bold">
                      {location}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
              <div className="rounded-full w-3 h-3 border-2 border-system-grey-400 mr-2" />
              <JourneyFromLine stops={stops.length} />
              <div
                className={
                  stop.isPrimaryTransport
                    ? 'w-10 h-10 rounded-md bg-system-grey-70 flex items-center justify-center'
                    : ''
                }
                data-testid={`icon-${stop.transportMode}`}
              >
                {Icon && (
                  <Icon className="w-6 h-6 mx-2 text-brand-breeze-primary-500" />
                )}
              </div>

              <JourneyToLine stops={stops.length} />

              {nextStopIsLast && (
                <>
                  <div className="rounded-full w-3 h-3 ml-2 border-2 border-system-grey-400" />
                  <div className="flex flex-col items-center absolute right-1.5 transform translate-x-1/2 top-8">
                    <div className="flex flex-col items-center">
                      <Typography color="secondary" level="caption">
                        {nextStop.label}
                      </Typography>
                      <Tooltip
                        content={nextStop.location.name}
                        placement="bottom"
                      >
                        <Typography color="primary" text="bold">
                          {nextLocation}
                        </Typography>
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
