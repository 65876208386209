import { useRef } from 'react';
import { Button } from 'react-aria-components';
import { createPortal } from 'react-dom';
import { FaXmark } from 'react-icons/fa6';
import type { AriaToastRegionProps } from '@react-aria/toast';
import { useToast } from '@react-aria/toast';
import { useToastRegion } from '@react-aria/toast';
import type { ToastState } from '@react-stately/toast';
import { ToastQueue, useToastQueue } from '@react-stately/toast';

export type ToastLevel = 'info' | 'success' | 'warning' | 'error';

export interface ToastContent {
  title: string;
  description?: string;
  level: ToastLevel;
}

interface ToastRegionProps extends AriaToastRegionProps {
  state: ToastState<ToastContent>;
}

export const toastQueue = new ToastQueue<ToastContent>({
  maxVisibleToasts: 5,
});

export function GlobalToastRegion(props: AriaToastRegionProps) {
  const state = useToastQueue(toastQueue);
  return state.visibleToasts.length > 0
    ? createPortal(<ToastRegion {...props} state={state} />, document.body)
    : null;
}

function ToastRegion({ state, ...props }: ToastRegionProps) {
  const ref = useRef(null);
  const { regionProps } = useToastRegion(props, state, ref);

  return (
    <div
      {...regionProps}
      ref={ref}
      className="fixed bottom-5 right-5 flex flex-col gap-2 z-[1000]"
    >
      {state.visibleToasts.map((toast) => (
        <Toast key={toast.key} toast={toast} state={state} />
      ))}
    </div>
  );
}

interface ToastProps {
  toast: {
    key: string;
    content: ToastContent;
  };
  state: ToastState<ToastContent>;
}

function Toast({ toast, state }: ToastProps) {
  const ref = useRef(null);
  const { toastProps, contentProps, titleProps, closeButtonProps } = useToast(
    { toast },
    state,
    ref,
  );

  const { title, description } = toast.content;

  return (
    <div
      {...toastProps}
      ref={ref}
      className={`grid grid-cols-[auto_1fr_auto] items-center gap-4 px-4 py-4 rounded-2xl shadow-lg bg-white`}
    >
      {/* TODO: implement toast level icons */}
      <div className="bg-system-blue-50 rounded-xl size-10 grid place-items-center p-3 text-brand-breeze-primary-300">
        <svg viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="currentColor"
            d="M0.427734 4.75002C0.427734 2.20951 2.48723 0.150024 5.02773 0.150024H9.47218C12.0127 0.150024 14.0722 2.20952 14.0722 4.75002V9.19447C14.0722 11.735 12.0127 13.7945 9.47218 13.7945H5.02773C2.48722 13.7945 0.427734 11.735 0.427734 9.19447V4.75002ZM5.02773 1.35002C3.14997 1.35002 1.62773 2.87225 1.62773 4.75002V9.19447C1.62773 11.0722 3.14996 12.5945 5.02773 12.5945H9.47218C11.3499 12.5945 12.8722 11.0722 12.8722 9.19447V4.75002C12.8722 2.87226 11.35 1.35002 9.47218 1.35002H5.02773ZM7.2499 6.37225C7.58127 6.37225 7.8499 6.64088 7.8499 6.97225V9.30558C7.8499 9.63695 7.58127 9.90558 7.2499 9.90558C6.91853 9.90558 6.6499 9.63695 6.6499 9.30558V6.97225C6.6499 6.64088 6.91853 6.37225 7.2499 6.37225ZM7.24985 5.41669C7.6794 5.41669 8.02763 5.06847 8.02763 4.63891C8.02763 4.20936 7.6794 3.86114 7.24985 3.86114C6.82029 3.86114 6.47207 4.20936 6.47207 4.63891C6.47207 5.06847 6.82029 5.41669 7.24985 5.41669Z"
          />
        </svg>
      </div>
      <div {...contentProps} className="grid">
        <div {...titleProps} className="font-semibold text-base">
          {title}
        </div>
        {description && (
          <div className="text-system-grey-750 text-base font-[400]">
            {description}
          </div>
        )}
      </div>
      <Button
        {...closeButtonProps}
        className="opacity-70 hover:opacity-100 size-4 text-system-grey-600 font-[400] self-start"
      >
        <FaXmark />
      </Button>
    </div>
  );
}
