import {
  type DeepNullable,
  type RecursivePartial,
} from '@breezeai-frontend/cargo-ui';

import { type AdditionalClause } from '../../../model/AdditionalClause';
import {
  type CurrencyDefinition,
  type CurrencyExchangeRate,
  type SupportedCurrencies,
} from '../../../model/CurrencyValue';
import { type Customer } from '../../../model/Customer';
import { type Distributor } from '../../../model/Distributor';
import { type OpenCover } from '../../../model/OpenCover';
import { type Place, type PlaceModel } from '../../../model/Place';
import { type Port } from '../../../model/Port';
import { type ExclusionReason, type Quote } from '../../../model/Quote';
import {
  type ContainerModeEnum,
  type ConveyanceType,
  type ShipmentSpecialCondition,
  type SpecialConditionOptionType,
} from '../../../model/Shipment';
import { type MapsApiPayload } from '../../../ui/certificates/types';
import { type PaginationBaseResponse } from '../types';
import { type AccountUser } from '../users/types';

export type QuotesSortableFields = 'id' | 'created_at' | 'status';

export type QuoteSubmissionSource = 'manual' | 'shipments_integration_flow';

export enum QuoteStatusEnum {
  READY_FOR_POLICY = 'ready_for_policy',
  PENDING_FOR_BREEZE_MANUAL_REVIEW = 'pending_for_breeze_manual_review',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  POLICY_ISSUED = 'policy_issued',
  DECLINED = 'declined',
  ARCHIVED = 'archived',
}

export type QuoteCoveragePackage = 'all_risks' | 'fpa';

export type CoverageDescription = {
  title: string;
  description: string;
};

export type QuotesErrorType = 'QuoteNotUpdatableException';

export type QuoteModel = {
  id: number;
  distributor_id?: number;
  primary_transport_mode_code?: ConveyanceType;
  created_time: ISODateTZ;
  status?: QuoteStatusEnum;
  active_policy_id?: number;
  submission_source?: QuoteSubmissionSource;
  secondary_transport_mode_code?: ConveyanceType;
  coverage_package?: QuoteCoveragePackage;
  origin_place?: PlaceModel;
  origin_port?: Port;
  origin_port_code?: string;
  destination_place?: PlaceModel;
  destination_port?: Port;
  destination_port_code?: string;
  eta?: ISODate;
  etd?: ISODate;
  issue_date?: ISODate;
  freight_cost?: number;
  freight_cost_currency?: CurrencyDefinition;
  duty_cost?: number;
  duty_cost_currency?: CurrencyDefinition;
  commodity?: {
    id: number;
    commodity_type: string;
  };
  commodity_id?: number;
  commodity_value?: number;
  commodity_currency?: CurrencyDefinition;
  commodity_external_description?: string;
  customer_premium_value?: number;
  customer_premium_currency?: CurrencyDefinition;
  premium_rate?: number;
  converted_customer_premium_value?: number;
  converted_tax_amount?: number;
  currency_exchange_rate?: CurrencyExchangeRate;
  insurer_premium_value?: number;
  insurer_premium_currency?: CurrencyDefinition;
  submitted_deductible_value?: number;
  submitted_deductible_currency_id?: number;
  customer_tax_amount?: number;
  tax_currency?: CurrencyDefinition;
  tax_description?: string;
  container_mode?: ContainerModeEnum;
  vessel_name?: string;
  value_insured?: number;
  special_conditions?: SpecialConditionOptionType[];
  container_ids?: string[];
  incoterm_code?: string;
  external_shipment_id?: string;
  external_reference?: string;
  created_by_user?: AccountUser;
  expiring_on?: ISODate;
  goods_marks?: string;
  exclusion_reasons?: ExclusionReason[];
  distributor?: Distributor;
  loading_place?: PlaceModel;
  loading_transport_mode_code?: ConveyanceType;
  delivery_place?: PlaceModel;
  delivery_transport_mode_code?: ConveyanceType;
  original_quote_id?: string;
  additional_clauses?: string[];
  quote_additional_clauses?: AdditionalClause[];
  letter_of_credit?: string;

  open_cover?: OpenCover;
  uplift_percentage: number;
};

export type EnrichedQuoteModel = QuoteModel & {
  distributor: Distributor;
  customer: Customer;
};

export type GetQuotesResponse = PaginationBaseResponse & {
  quotes: EnrichedQuoteModel[];
};

export type GetQuotesNormalizedResponse = Omit<GetQuotesResponse, 'quotes'> & {
  quotes: Quote[];
};

export type QuoteSubmissionPayload = {
  transport_mode_code?: ConveyanceType;
  origin_port_code?: string;
  origin_place_provider_details?: MapsApiPayload | null;
  destination_port_code?: string;
  destination_place_provider_details?: MapsApiPayload | null;

  loading_place_provider_details?: MapsApiPayload | null;
  loading_transport_mode_code?: ConveyanceType | null;

  delivery_transport_mode_code?: ConveyanceType | null;
  delivery_place_provider_details?: MapsApiPayload | null;

  coverage_package: QuoteCoveragePackage;
  commodity: {
    name?: string;
    type_id?: number;
    value?: number;
    description?: string;
    currency_code?: SupportedCurrencies;
  };
  cargo_owner: {
    id?: number;
    name?: string;
  };
  distributor_id?: number;
  freight_cost?: number | null;
  freight_cost_currency?: SupportedCurrencies;
  duty_cost?: number | null;
  duty_cost_currency?: SupportedCurrencies;
  container_mode_id?: number;
  special_conditions?: Array<{ value: ShipmentSpecialCondition }>;
  external_reference?: string;
  external_shipment_id?: string;

  // TODO: remove these when Secondaries feature is released
  origin_place?: Place;
  destination_place?: Place;
  secondary_transport_mode_code?: ConveyanceType;
  letter_of_credit?: string;
};

export type QuoteDuplicationPayload = QuoteSubmissionPayload & {
  original_quote_id: string;
};
/**
 * Optionally allow every key in {@link QuoteSubmissionPayload} except for firebase_uid,
 * either with its type or null (for deletion)
 */
export type QuoteUpdatePayload = RecursivePartial<
  DeepNullable<Omit<QuoteSubmissionPayload, 'firebase_uid'>>
>;

export type QuoteMutationActions = 'create' | 'update' | 'duplicate';
