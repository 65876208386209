import {
  Button as RACButton,
  type ButtonProps as RACButtonProps,
  composeRenderProps,
  Label,
  ProgressBar,
} from 'react-aria-components';
import { ImSpinner8 } from 'react-icons/im';
import { type VariantProps } from 'tailwind-variants';

import { Typography } from '../Typography/Typography';
import { button } from './variants';

type ButtonElementProps = RACButtonProps;

export interface ButtonProps
  extends Omit<ButtonElementProps, 'className'>,
    VariantProps<typeof button> {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children?: React.ReactNode;
  customStyles?: string;
  isLoading?: boolean;
  label?: string;
  loadingText?: string;
}

export function Button({
  leftIcon,
  rightIcon,
  children,
  label,
  isLoading,
  loadingText,
  ...props
}: ButtonProps) {
  return (
    <RACButton
      {...props}
      isDisabled={props.isDisabled || isLoading}
      className={composeRenderProps(
        props.customStyles,
        (_className, renderProps) =>
          button({
            ...renderProps,
            variant: props.variant,
            size: props.size,
            width: props.width,
            className: props.customStyles,
          }),
      )}
    >
      {Boolean(leftIcon && !isLoading) && <span>{leftIcon}</span>}
      {Boolean(!isLoading && children) && <span>{children}</span>}
      {Boolean(!isLoading && label) && (
        <Typography level="button">{label}</Typography>
      )}
      {Boolean(rightIcon && !isLoading) && <span>{rightIcon}</span>}
      {Boolean(isLoading) && (
        <ProgressBar
          aria-label="Loading"
          isIndeterminate
          className="flex flex-row justify-center items-center gap-2"
        >
          <ImSpinner8 className="animate-spin" />
          {loadingText && (
            <Typography level="button">
              <Label>{loadingText}</Label>{' '}
            </Typography>
          )}
        </ProgressBar>
      )}
    </RACButton>
  );
}
