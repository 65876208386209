import invariant from 'tiny-invariant';

import { type PolicyFlowFormsData } from '../../../ui/policy-flow/context/types';
import { get, post, put } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import {
  type FilterableFields,
  type PaginationBaseQueryParams,
} from '../types';
import { normalizeQuoteResponse } from './normalizers';
import {
  type CoverageDescription,
  type EnrichedQuoteModel,
  type GetQuotesNormalizedResponse,
  type GetQuotesResponse,
  type QuoteModel,
  type QuotesSortableFields as QuotesSortableFields,
  type QuoteSubmissionPayload,
  type QuoteUpdatePayload,
} from './types';

const createQuoteRequestPayload = (
  data: PolicyFlowFormsData,
): QuoteSubmissionPayload => {
  const { transportMethod, commodity, customer, origin, destination } = data;

  invariant(transportMethod.primary, 'Primary transport mode is required');

  return {
    coverage_package: data.coverageType,
    transport_mode_code: transportMethod.primary,
    secondary_transport_mode_code: transportMethod.secondary,
    origin_place: origin?.place,
    origin_port_code: origin?.port?.code,
    destination_place: destination?.place,
    destination_port_code: destination?.port?.code,
    distributor_id: data.distributorId,
    commodity: {
      name: commodity?.category,
      type_id: commodity?.categoryId,
      value: commodity?.value,
      description: commodity?.description,
      currency_code: data.currency,
    },
    cargo_owner: {
      id: customer?.id,
      name: customer?.company_name,
    },
    freight_cost: data.freightCost,
    freight_cost_currency: data.freightCostCurrency,
    duty_cost: data.dutyCost,
    duty_cost_currency: data.dutyCostCurrency,
    container_mode_id: data.containerModeId,
    external_reference: data.externalReference,
    external_shipment_id: data.externalShipmentId,
    special_conditions:
      data.specialConditions?.map((value) => ({ value })) ?? [],
  };
};

const createQuoteUpdatePayload = (
  updates: PolicyFlowFormsData,
): QuoteUpdatePayload => {
  const { transportMethod, commodity, customer, origin, destination } = updates;

  return {
    coverage_package: updates.coverageType,
    transport_mode_code: transportMethod?.primary,
    secondary_transport_mode_code: transportMethod?.secondary,
    origin_place: origin?.place,
    origin_port_code: origin?.port?.code,
    destination_place: destination?.place,
    destination_port_code: destination?.port?.code,
    distributor_id: updates.distributorId,
    commodity: {
      name: commodity?.category,
      type_id: commodity?.categoryId,
      value: commodity?.value,
      description: commodity?.description,
      currency_code: updates.currency,
    },
    external_reference: updates.externalReference,
    cargo_owner: customer
      ? {
          id: customer?.id,
          name: customer?.company_name,
        }
      : undefined,
    freight_cost: updates.freightCost,
    freight_cost_currency: updates.freightCostCurrency,
    duty_cost: updates.dutyCost,
    duty_cost_currency: updates.dutyCostCurrency,
    container_mode_id: updates.containerModeId,
    special_conditions:
      updates?.specialConditions && updates?.specialConditions.length === 0
        ? null
        : updates.specialConditions?.map((value) => ({ value })),
  };
};

export const getQuotes = async (
  params?: PaginationBaseQueryParams<QuotesSortableFields, FilterableFields>,
): Promise<GetQuotesNormalizedResponse> => {
  const { data } = await post<GetQuotesResponse>(
    `${bffServiceUrl}/bff_quotes/get`,
    params,
  );

  return {
    ...data,
    quotes: data.quotes.map(normalizeQuoteResponse),
  };
};

// TODO: no longer used - remove
export const getQuoteById = async (
  quoteId: number | string,
): Promise<QuoteModel> => {
  const { data } = await get<EnrichedQuoteModel>(
    `${bffServiceUrl}/bff_quotes/${quoteId}`,
  );

  return data;
};

export const getQuoteConditions = async (
  quoteId: number | string,
): Promise<CoverageDescription[]> => {
  const { data } = await get<CoverageDescription[]>(
    `${bffServiceUrl}/bff_quotes/${quoteId}/conditions`,
  );

  return data;
};

export const createQuote = async (
  quoteSubmissionData: PolicyFlowFormsData,
): Promise<EnrichedQuoteModel> => {
  const payload = createQuoteRequestPayload(quoteSubmissionData);

  const { data } = await post<EnrichedQuoteModel>(
    `${bffServiceUrl}/form/quote`,
    payload,
  );

  return data;
};

export const updateQuote = async (
  quoteId: number | string,
  updates: PolicyFlowFormsData,
): Promise<EnrichedQuoteModel> => {
  const payload = createQuoteUpdatePayload(updates);

  const { data } = await put<EnrichedQuoteModel>(
    `${bffServiceUrl}/bff_quotes/${quoteId}`,
    payload,
  );

  return data;
};
