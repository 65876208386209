import { type ErrorResponse } from 'react-router-dom';
import {
  useMutation,
  type UseMutationOptions,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { stringify } from 'query-string';
import invariant from 'tiny-invariant';

import { type AdditionalClause } from '../../../model/AdditionalClause';
import {
  type CurrencyDefinition,
  type CurrencyExchangeRate,
} from '../../../model/CurrencyValue';
import { type Distributor } from '../../../model/Distributor';
import { type OpenCover } from '../../../model/OpenCover';
import { type PlaceModel } from '../../../model/Place';
import { type Port } from '../../../model/Port';
import { type ExclusionReason } from '../../../model/Quote';
import {
  type ContainerModeEnum,
  type ConveyanceType,
  type SpecialConditionOptionType,
} from '../../../model/Shipment';
import { type NetworkResponse } from '../../AuthRequests';
import { getApiClient } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import { type PaginationBaseQueryParams } from '../types';
import { type AccountUser } from '../users/types';
import { get, post, put } from '../utils';
import { normalizeQuoteResponse } from './normalizers';
import {
  type EnrichedQuoteModel,
  type GetQuotesResponse,
  type QuoteCoveragePackage,
  type QuoteMutationActions,
  type QuotesSortableFields as QuotesSortableFields,
  type QuoteStatusEnum,
  type QuoteSubmissionPayload,
  type QuoteSubmissionSource,
  type QuoteUpdatePayload,
} from './types';

export const useQuotesSuspense = (
  params?: PaginationBaseQueryParams<QuotesSortableFields>,
) => {
  const queryParams = params ? `?${stringify(params)}` : '';

  return useSuspenseQuery({
    queryKey: ['quotes', params],
    // TODO: remove the null check and use component composition as described: https://github.com/TanStack/query/discussions/6206#discussioncomment-7343078
    queryFn: () =>
      params
        ? get<GetQuotesResponse>(`${bffServiceUrl}/bff_quotes${queryParams}`)
        : null,

    select: (data) =>
      data && {
        ...data,
        quotes: data.quotes.map(normalizeQuoteResponse),
      },
  });
};

export const useQuoteSuspense = ({
  quoteId,
}: {
  quoteId: string | undefined;
}) => {
  return useSuspenseQuery({
    queryKey: ['quotes', quoteId],
    queryFn: async () => {
      // TODO: remove the null check and use component composition as described: https://github.com/TanStack/query/discussions/6206#discussioncomment-7343078
      if (quoteId === undefined) return null;
      const apiClient = await getApiClient();
      const { data } = await apiClient.getQuoteDetails({
        quoteId,
      });

      return data;
    },
    select: (data) =>
      data &&
      normalizeQuoteResponse({
        ...data,
        // TODO HACK: id is set as Optional in Backend. Should be adjusted.
        id: data.id!,
        // TODO HACK: created_time is set as Optional in Backend. Should be adjusted.
        created_time: data.created_time!,
        // TODO HACK: status should be narrowed down to QuoteStatusEnum
        status: data.status as QuoteStatusEnum | undefined,
        // TODO HACK: submission_source should be narrowed down to QuoteSubmissionSource
        submission_source: data.submission_source as
          | QuoteSubmissionSource
          | undefined,
        // TODO HACK: primary_transport_mode_code should be narrowed down to ConveyanceType
        primary_transport_mode_code: data.primary_transport_mode_code as
          | ConveyanceType
          | undefined,
        // TODO HACK: secondary_transport_mode_code should be narrowed down to ConveyanceType
        secondary_transport_mode_code: data.secondary_transport_mode_code as
          | ConveyanceType
          | undefined,
        // TODO HACK: coverage_package should be narrowed down to QuoteCoveragePackage
        coverage_package: data.coverage_package as
          | QuoteCoveragePackage
          | undefined,
        // TODO HACK: origin_place.provider is Optional in Backend. Should be adjusted.
        origin_place: data.origin_place as PlaceModel | undefined,
        // TODO HACK: origin_port.functions should be narrowed down to PortType[]
        origin_port: data.origin_port as Port | undefined,
        // TODO HACK: destination_place.provider is Optional in Backend. Should be adjusted.
        destination_place: data.destination_place as PlaceModel | undefined,
        // TODO HACK: origin_port.functions should be narrowed down to PortType[]
        destination_port: data.destination_port as Port | undefined,
        // TODO HACK: freight_cost_currency.code should be narrowed down to SupportedCurrencies
        freight_cost_currency: data.freight_cost_currency as
          | CurrencyDefinition
          | undefined,
        // TODO HACK: duty_cost_currency.code should be narrowed down to SupportedCurrencies
        duty_cost_currency: data.duty_cost_currency as
          | CurrencyDefinition
          | undefined,
        // TODO HACK: commodity type and id are Optional in Backend. Should be adjusted.
        commodity: data.commodity as
          | {
              id: number;
              commodity_type: string;
            }
          | undefined,
        // TODO HACK: commodity_currency.code should be narrowed down to SupportedCurrencies
        commodity_currency: data.commodity_currency as
          | CurrencyDefinition
          | undefined,
        // TODO HACK: customer_premium_currency.code should be narrowed down to SupportedCurrencies
        customer_premium_currency: data.customer_premium_currency as
          | CurrencyDefinition
          | undefined,
        // TODO HACK: currency_exchange_rate.code should be narrowed down to SupportedCurrencies
        currency_exchange_rate: data.currency_exchange_rate as
          | CurrencyExchangeRate
          | undefined,
        // TODO HACK: insurer_premium_currency.code should be narrowed down to SupportedCurrencies
        insurer_premium_currency: data.insurer_premium_currency as
          | CurrencyDefinition
          | undefined,
        // TODO HACK: tax_currency.code should be narrowed down to SupportedCurrencies
        tax_currency: data.tax_currency as CurrencyDefinition | undefined,
        // TODO HACK: container_mode should be narrowed down to ContainerModeEnum
        container_mode: data.container_mode as ContainerModeEnum | undefined,
        // TODO HACK: special_conditions attributes are Optional in Backend. Should be adjusted.
        special_conditions: data.special_conditions as
          | SpecialConditionOptionType[]
          | undefined,
        // TODO HACK: created_by_user attributes are Optional in Backend. Should be adjusted.
        created_by_user: data.created_by_user as AccountUser | undefined,
        // TODO HACK: exclusion_reasons is very loosly typed in Backend. Should be adjusted.
        exclusion_reasons: data.exclusion_reasons as
          | ExclusionReason[]
          | undefined,
        // TODO HACK: distributor attributes are Optional in Backend. Should be adjusted.
        distributor: data.distributor as Distributor | undefined,
        // TODO HACK: loading_place.provider is Optional in Backend. Should be adjusted.
        loading_place: data.loading_place as PlaceModel | undefined,
        // TODO HACK: delivery_place.provider is Optional in Backend. Should be adjusted.
        delivery_place: data.delivery_place as PlaceModel | undefined,
        // TODO HACK: original_quote_id is number in Backend. Should be adjusted.
        original_quote_id: data.original_quote_id
          ? String(data.original_quote_id)
          : undefined,
        // TODO HACK: additional_clauses is not an array in Backend. Should be adjusted.
        additional_clauses: data.additional_clauses
          ? [data.additional_clauses]
          : undefined,
        // TODO HACK: quote_additional_clauses attributes should be narrowed down to AdditionalClause[]
        quote_additional_clauses: data.quote_additional_clauses as
          | AdditionalClause[]
          | undefined,
        // TODO HACK: open_cover attributes are Optional in Backend. Should be adjusted.
        open_cover: data.open_cover as OpenCover | undefined,
      }),
  });
};

export const useQuoteMutation = ({
  quoteId,
  action,
  options,
}: {
  quoteId?: string;
  action: QuoteMutationActions;
  options: UseMutationOptions<
    NetworkResponse<EnrichedQuoteModel>,
    ErrorResponse,
    QuoteSubmissionPayload | QuoteUpdatePayload
  >;
}) => {
  let endpoint = `${bffServiceUrl}/form/quote`;
  let method = post;

  switch (action) {
    case 'update':
      invariant(quoteId, 'quoteId is required for update action');
      endpoint = `${bffServiceUrl}/bff_quotes/${quoteId}`;
      method = put;
      break;
    case 'duplicate':
      endpoint = `${bffServiceUrl}/bff_quotes/duplicate`;
      method = post;
      break;
    case 'create':
      break;
  }

  return useMutation({
    mutationFn: (payload) => method<EnrichedQuoteModel>(endpoint, payload),
    ...options,
  });
};
