import { useRef, useState } from 'react';
import {
  ComboBox,
  ComboBoxItem,
  type ComboBoxProps,
} from '@breezeai-frontend/cargo-ui';
import { keepPreviousData } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { v4 as uuid } from 'uuid';

import { type PlaceSuggestion } from '../../../model/Place';
import { usePlaces } from '../../certificates/certificateApiHooks';

export type PlacesComboBoxProps = Omit<
  ComboBoxProps<PlaceSuggestion>,
  'children'
>;

export function PlacesComboBox({
  onInputChange,
  ...props
}: PlacesComboBoxProps) {
  const [query, setQuery] = useState<string | undefined>();
  const debouncedQuery = useDebounce(query, 500);

  const session_token = useRef(uuid()).current;

  const { data, isFetching } = usePlaces({
    options: {
      queryKey: ['places', debouncedQuery!],
      enabled: !!debouncedQuery,
      // TODO can be removed as throwOnError is true by default (set on queryClient)
      throwOnError: true,
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
      gcTime: 0,
    },
    params: {
      query: debouncedQuery,
      session_token,
    },
  });

  return (
    <ComboBox
      items={data?.results}
      onInputChange={(value) => {
        onInputChange && onInputChange(value);
        setQuery(value);
      }}
      isFetchingItems={isFetching}
      allowsEmptyCollection
      emptyCollectionMessage={
        query ? 'No options' : 'Please type and select an option.'
      }
      {...props}
    >
      {data?.results.map(({ description, place_id }) => (
        <ComboBoxItem
          key={JSON.stringify({ place_id, description, session_token })}
          id={JSON.stringify({ place_id, description, session_token })}
          data-testid={description}
          textValue={description}
        >
          {description}
        </ComboBoxItem>
      ))}
    </ComboBox>
  );
}
