import { useCallback } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ErrorFlag } from '@breeze-ai/ui-library';

import { type PolicyFlowConfigurationResponse } from '../../../network/apis/configurations/types';
import { normalizePolicyResponse } from '../../../network/apis/policies/normalizers';
import { createPolicy } from '../../../network/apis/policies/policies';
import { type PoliciesErrorType } from '../../../network/apis/policies/types';
import { useQuoteSuspense } from '../../../network/apis/quotes/hooks';
import {
  type BaseErrorType,
  type ErrorResponse,
} from '../../../network/apis/types';
import { useRouteParams } from '../../../router/router-hooks';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { FLOW_ERROR_MESSAGES, FlowSteps } from '../constants';
import {
  type PolicyFlowError,
  type PolicyFlowUrlParams,
} from '../context/types';
import {
  usePolicyFlowContext,
  usePolicyFlowContextAction,
  useSetFlowError,
  useSetFlowStep,
} from './context-hooks';
import { useShipmentInformationForm } from './use-shipment-information-form';

const getErrorDetails = (
  errorType: PoliciesErrorType | BaseErrorType,
  retry?: () => void,
): PolicyFlowError => {
  switch (errorType) {
    case 'ExcludedCargoOwnerAddress':
      return {
        illustration: <ErrorFlag />,
        title: 'Sorry, this cargo owner is located in an excluded territory',
        message: FLOW_ERROR_MESSAGES.excludedCargoOwnerAddress,
        fallbackStep: FlowSteps.SHIPMENT_INFORMATION,
      };

    default:
    case 'GenericException':
      return {
        message: FLOW_ERROR_MESSAGES.createPolicy,
        fallbackStep: FlowSteps.SHIPMENT_INFORMATION,
        retry,
      };
  }
};

export const useSubmitPolicyCreationRequest = (
  configuration: PolicyFlowConfigurationResponse,
) => {
  const {
    params: { quoteId },
  } = useRouteParams<PolicyFlowUrlParams>();
  const { data: quote } = useQuoteSuspense({ quoteId });
  const navigate = useNavigate();

  const { forms } = usePolicyFlowContext();
  const setFlowStep = useSetFlowStep();
  const setFlowError = useSetFlowError();
  const dispatch = usePolicyFlowContextAction();
  const { data: policyFormData } = useShipmentInformationForm(configuration);

  return useCallback(async () => {
    if (!quote || !forms.data) {
      return;
    }

    setFlowStep(FlowSteps.LOADING);

    try {
      const policy = await createPolicy(
        // TODO: The Quote side of the form uses a different state, so this is
        // a quick fix to make sure externalReference is set when creating the Policy
        // This hook will be removed once we implement a new Policy form.
        {
          ...forms.data,
          externalReference:
            quote.external_reference || policyFormData.externalReference,
        },
        quote,
      );
      dispatch({
        type: 'set-policy-data',
        payload: normalizePolicyResponse(policy),
      });

      navigate(
        {
          pathname: `/policies/${policy.id}`,
          search: `?${createSearchParams({ ready: 'true' })}`,
        },
        {
          replace: true,
        },
      );
    } catch (error) {
      reportException(error);
      const { response } = error as ErrorResponse<PoliciesErrorType>;
      const { error_type } = response.data;
      const flowError = getErrorDetails(error_type);

      setFlowError(flowError);
    }
  }, [
    navigate,
    quote,
    forms.data,
    setFlowStep,
    dispatch,
    setFlowError,
    policyFormData,
  ]);
};
