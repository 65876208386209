import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { stringify } from 'query-string';

import { type Customer } from '../../../model/Customer';
import { bffServiceUrl } from '../../netconfig';
import { normalizePlaceResponse } from '../places/normalizers';
import { type ErrorResponse, type PaginationBaseQueryParams } from '../types';
import { get } from '../utils';
import { type CustomersSortOption, type GetCustomersResponse } from './types';

export const useCustomers = ({
  options,
  params,
}: {
  params?: PaginationBaseQueryParams<CustomersSortOption>;
  options?: UseQueryOptions<
    GetCustomersResponse,
    ErrorResponse,
    Customer[],
    Array<string>
  >;
}) => {
  const queryParams = params ? `?${stringify(params)}` : '';

  const key = ['customers', stringify(params || {})];

  return useQuery({
    queryKey: key,
    queryFn: () =>
      get<GetCustomersResponse>(`${bffServiceUrl}/customers${queryParams}`),
    select: ({ customers }) =>
      customers.map((customer) => ({
        id: customer.id,
        company_name: customer.company_name,
        distributor_id: customer.distributor_id,
        address: normalizePlaceResponse({
          provider_place_uuid: customer?.address_provider_uuid ?? '',
          provider: customer?.address_provider ?? '',
          name: customer?.address_name ?? '',
          full_address: customer?.address_line1 ?? '',
          country_name: customer?.country_name,
          country_code: customer?.country_code,
          state_code: customer?.state_code,
          state_name: customer?.state_name,
          city: customer?.city,
          geolocation: {
            lat: Number(customer?.address_lat),
            lng: Number(customer?.address_lon),
          },
        }),
      })),

    ...options,
  });
};
