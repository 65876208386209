import { useSuspenseQuery } from '@tanstack/react-query';

import { type PolicyFlowConfigurationResponse } from '../../../../../network/apis/configurations/types';
import { get } from '../../../../../network/apis/utils';
import { bffServiceUrl } from '../../../../../network/netconfig';

export const useFormConfigurationSuspense = () => {
  return useSuspenseQuery({
    queryKey: ['form', 'quote'],
    queryFn: () =>
      get<PolicyFlowConfigurationResponse>(`${bffServiceUrl}/form/quote`),
  });
};
