import { type PolicyFlowContextAction, type PolicyFlowState } from './types';
import { consolidateFormData } from './utils';

export const PolicyFlowContextReducer = (
  state: PolicyFlowState,
  action: PolicyFlowContextAction,
): PolicyFlowState => {
  switch (action.type) {
    case 'set-flow-step':
      return {
        ...state,
        step: action.payload,
      };

    case 'set-flow-error':
      return {
        ...state,
        error: action.payload,
      };

    case 'set-form-configuration':
      return {
        ...state,
        configuration: action.payload,
      };

    case 'set-form-data':
      return {
        ...state,
        forms: {
          ...state.forms,
          data: consolidateFormData(state.forms.data, action.payload),
        },
      };

    case 'set-form-data-update':
      return {
        ...state,
        forms: {
          ...state.forms,
          updates: consolidateFormData(state.forms.updates, action.payload),
        },
      };

    case 'reset-form-edits':
      return {
        ...state,
        forms: {
          ...state.forms,
          updates: undefined,
        },
      };

    case 'set-form-error':
      const errors = [...(state.forms?.errors ?? {})];

      return {
        ...state,
        forms: {
          ...state.forms,
          errors: [
            ...errors.filter((e) => e.field !== action.payload.field),
            ...(action.payload.error ? [action.payload] : []),
          ],
        },
      };

    case 'set-shipment-data': {
      return {
        ...state,
        shipment: action.payload,
      };
    }

    case 'set-quote-data': {
      return {
        ...state,
        quote: action.payload,
      };
    }

    case 'set-policy-data': {
      return {
        ...state,
        policy: action.payload,
      };
    }
  }
};
