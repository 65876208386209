import axios from 'axios';

import { Configuration, QuotesApi } from '../generated/api-client';
import { fetchIdToken } from './apis/auth/auth';
import { NoAuthTokenError } from './apis/auth/errors';
import { bffServiceUrl } from './netconfig';

let apiClientSingleton: QuotesApi | undefined = undefined;

export async function getApiClient() {
  if (apiClientSingleton !== undefined) {
    return apiClientSingleton;
  }
  const token = await fetchIdToken();
  const apiConfig = new Configuration({
    accessToken: token ?? undefined,
    basePath: bffServiceUrl,
  });
  apiClientSingleton = new QuotesApi(apiConfig);
  return apiClientSingleton;
}

export type NetworkRequestOptions = {
  auth?: boolean;
};

export interface NetworkResponse<T> {
  data: T;
  status: number;
  statusText: string;
}

interface RequestConfiguration {
  headers?: {
    Authorization?: string;
  };
}

const createRequestConfig = async ({
  auth = true,
}: NetworkRequestOptions = {}): Promise<RequestConfiguration> => {
  if (!auth) {
    return {};
  }
  const token = await fetchIdToken();

  if (!token && process.env.NODE_ENV !== 'test') {
    throw new NoAuthTokenError();
  }

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const post = async <T>(
  url: string,
  payload?: unknown,
  options?: NetworkRequestOptions,
): Promise<NetworkResponse<T>> => {
  const config = await createRequestConfig(options);
  const res = await axios.post<T>(url, payload, config);
  return res;
};

export const put = async <T>(
  url: string,
  payload?: unknown,
  options?: NetworkRequestOptions,
): Promise<NetworkResponse<T>> => {
  const config = await createRequestConfig(options);

  return await axios.put<T>(url, payload, config);
};

export const get = async <T>(
  url: string,
  payload?: unknown,
  options?: NetworkRequestOptions,
): Promise<NetworkResponse<T>> => {
  const config = await createRequestConfig(options);

  return await axios.get<T>(url, {
    ...config,
    params: payload,
  });
};
