import { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  type BaseQueryParams,
  type PageState,
  ServerSideTableV2,
} from '@breeze-ai/ui-library';
import { Button, sortByMultipleAttributes } from '@breezeai-frontend/cargo-ui';

import { useFeatureToggle } from '../../../context/auth/auth-hooks';
import { type InvoiceDetails } from '../../../model/Invoice';
import { getInvoicePolicies } from '../../../network/apis/invoices/invoices';
import { type InvoicePolicyItem } from '../../../network/apis/invoices/types';
import { type PoliciesSortableFields } from '../../../network/apis/policies/types';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useSectionError } from '../../components/Section/SectionContext';
import {
  CargoOwnersFilter,
  CreatedByFilter,
  useFilterStore,
} from '../../components/TableFilters';
import styles from './BillingItemsTable.module.scss';
import { useBillingItemsTableColumns } from './use-billing-items-table-columns';

type BillingItemsTableProps = {
  invoiceId: string;
  hideTaxInfo?: InvoiceDetails['hide_tax_info'];
};

export const BillingItemsTable = ({
  invoiceId: invoiceId,
  hideTaxInfo = false,
}: BillingItemsTableProps) => {
  const DEFAULT_QUERY_PARAMS = {
    limit: 50,
  };
  const { setError } = useSectionError();
  const [queryParams, setQueryParams] =
    useState<BaseQueryParams>(DEFAULT_QUERY_PARAMS);
  const enableFilters = useFeatureToggle('enable_column_filters');
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const columns = useBillingItemsTableColumns(hideTaxInfo);
  const { filters, clearFilters } = useFilterStore((state) => ({
    filters: state.filters,
    clearFilters: state.clearFilters,
  }));

  // clear filter when navigating away from page
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, [clearFilters]);

  useEffect(() => {});

  const fetchBillingItems = useCallback(
    async (params: BaseQueryParams): Promise<PageState<InvoicePolicyItem>> => {
      return getInvoicePolicies(invoiceId, {
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        sort_by: params?.sortBy as PoliciesSortableFields,
        page_pointer: params?.pagePointer,
        filters,
        paginate: true,
      })
        .then((page) => {
          return {
            records: sortByMultipleAttributes(
              page.billing_items,
              ['policy_issue_date', 'cargo_owner', 'certificate_number'],
              {
                policy_issue_date: (a, b) =>
                  new Date(a.policy_issue_date).getTime() -
                  new Date(b.policy_issue_date).getTime(),
                cargo_owner: (a, b) =>
                  (a.cargo_owner?.name ?? '')?.localeCompare(
                    b.cargo_owner?.name ?? '',
                  ),
                certificate_number: (a, b) =>
                  a.certificate_number?.localeCompare(b.certificate_number),
              },
            ),
            nextPagePointer: page.next_page_pointer,
            prevPagePointer: page.prev_page_pointer,
            totalRows: page.total_num_records,
          };
        })
        .catch((e) => {
          reportException(e);
          setError(e);
          return { records: [] };
        });
    },
    [filters, setError, invoiceId],
  );

  return (
    <ServerSideTableV2<InvoicePolicyItem>
      fetchPage={fetchBillingItems}
      columns={columns}
      getRowId={(item) => item.certificate_number}
      testId="invoice-billing-items-table"
      actions={{ search: true, pageSize: true, filters: true }}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      pageSize={50}
      pagination={true}
      setCurrentPageNumber={setCurrentPageNumber}
      currentPageNumber={currentPageNumber}
      autoHeight={true}
      filters={
        enableFilters ? (
          <ErrorBoundary fallbackRender={() => <></>}>
            <div className={styles.filters}>
              <ErrorBoundary fallbackRender={() => <></>}>
                <CreatedByFilter />
              </ErrorBoundary>
              <ErrorBoundary fallbackRender={() => <></>}>
                <CargoOwnersFilter label="Cargo owner" />
              </ErrorBoundary>
              <Button
                onPress={clearFilters}
                variant="ghost"
                width="fixed"
                isDisabled={!filters.length}
                label="Clear filters"
              />
            </div>
          </ErrorBoundary>
        ) : undefined
      }
      searchProps={{ placeholder: 'Search policy or external reference...' }}
    />
  );
};
