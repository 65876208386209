import { type BaseQueryParams } from '@breeze-ai/ui-library';

import {
  type FilterableFields,
  type FilterBaseParam,
} from '../../../network/apis/types';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { getStorageLocations } from '../StoragePage/StorageTable/StorageTable';
import { type StorageLocationsSortableFields } from '../types';

interface StorageLocationsParams
  extends Omit<BaseQueryParams, 'filters' | 'sortBy' | 'pagePointer'> {
  filters: FilterBaseParam<FilterableFields>[];
  sortBy: StorageLocationsSortableFields;
  pagePointer: string | undefined;
  paginate: boolean;
}

// TODO This should never have been a mutation. We have not used react query with tables as the tables are not compatible. This approach does not provide any benefit over merely making a post request.
export const useStorageLocations = () => {
  return async (params: StorageLocationsParams) => {
    try {
      const page = await getStorageLocations({
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        sort_by: params?.sortBy as StorageLocationsSortableFields,
        page_pointer: params?.pagePointer,
        filters: params?.filters,
        paginate: true,
      });
      return {
        records: page.locations,
        nextPagePointer: page.next_page_pointer,
        prevPagePointer: page.prev_page_pointer,
        totalRows: page.total_num_records,
      };
    } catch (e) {
      reportException(e);
      return {
        records: [],
        nextPagePointer: null,
        prevPagePointer: null,
        totalRows: 0,
      };
    }
  };
};
