import { getTheme } from '../utils/getTheme';

const breeze = {
  terms: {
    policy: {
      singular: 'policy',
      plural: 'policies',
    },
  },
  quotesTable: {
    searchPlaceholder: 'Search Quote or Shipment ID',
    columns: {
      id: {
        title: 'ID',
      },
      commodity: {
        title: 'Commodity',
      },
    },
  },
  quoteForm: {
    title: 'Cargo Details',
  },
  policiesTable: {
    searchPlaceholder: 'Search Policy or Shipment ID',
    columns: {
      commodity: {
        title: 'Commodity',
      },
    },
  },
  fields: {
    cargoOwner: 'Cargo Owner',
    distributor: 'Freight Forwarder',
    commodity: {
      category: 'Commodity Category',
      description: 'Commodity Description',
      value: 'Commodity Value',
    },
    bookingReference: 'Booking Reference',
    containers: 'Containers',
    etd: 'ETD',
    eta: 'ETA',
    issueDate: 'Issue Date',
  },
  quoteSummaryCard: {
    title: 'Details Summary',
  },
  policyDetails: {
    commodity: 'Commodity',
    cargoValue: 'Cargo Value',
  },
};

const wtw = {
  terms: {
    policy: {
      singular: 'certificate',
      plural: 'certificates',
    },
  },
  quotesTable: {
    searchPlaceholder: 'Search Quote or Reference',
    columns: {
      id: {
        title: 'Quote ID',
      },
      commodity: {
        title: 'Description of Cargo',
      },
    },
  },
  quoteForm: {
    title: 'Shipment Details',
  },
  policiesTable: {
    searchPlaceholder: 'Search Certificate or Reference',
    columns: {
      commodity: {
        title: 'Description of Cargo',
      },
    },
  },
  fields: {
    cargoOwner: 'Named Assured',
    distributor: 'Primary Assured',
    commodity: {
      category: 'Cargo Category',
      description: 'Description of Cargo',
      value: 'Commercial Invoice Value',
    },
    bookingReference: 'Reference',
    containers: 'Marks & Numbers',
    etd: 'Date of Departure',
    eta: 'Date of Arrival',
    issueDate: 'Issue Date',
  },
  quoteSummaryCard: {
    title: 'Summary',
  },
  policyDetails: {
    commodity: 'Description of Cargo',
    cargoValue: 'Commercial Invoice Value',
  },
};

export default getTheme() === 'wtw' ? wtw : breeze;
