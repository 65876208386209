import { type ReactNode, Suspense } from 'react';
import { Typography } from '@breeze-ai/ui-library';
import { Spinner } from '@breezeai-frontend/cargo-ui';
import classnames from 'classnames';

import styles from './SectionLayout.module.scss';

type MainPageLayoutProps = {
  title?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  testId?: string;
  className?: string;
  contentClassName?: string;
};

// TODO This component should be deprecated in favor of using the SectionLayout from cargo-ui
/** @deprecated use SectionLayout from cargo-ui instead */
export const SectionLayout = ({
  title,
  actions,
  children,
  testId,
  className,
  contentClassName,
}: MainPageLayoutProps) => {
  return (
    <Suspense
      fallback={
        <Spinner className="self-center" data-testid="section-layout-spinner" />
      }
    >
      <div
        className={classnames(styles.sectionLayoutWrapper, className)}
        data-testid={testId}
      >
        <div className={styles.titleBar}>
          <Typography level="h2">{title}</Typography>
          <div className={styles.actions}>{actions}</div>
        </div>
        <div className={classnames(styles.content, contentClassName)}>
          {children}
        </div>
      </div>
    </Suspense>
  );
};
