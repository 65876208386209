import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { stringify } from 'query-string';

import { bffServiceUrl } from '../../netconfig';
import { type PaginationBaseQueryParams } from '../types';
import { get } from '../utils';
import {
  type AccountUser,
  type GetUsersResponse,
  type UsersSortOption,
} from './types';

export const useUsers = ({
  options,
  params,
}: {
  params?: PaginationBaseQueryParams<UsersSortOption>;
  options?: UseQueryOptions<
    GetUsersResponse,
    unknown,
    AccountUser[],
    Array<string>
  >;
}) => {
  const queryParams = params ? `?${stringify(params)}` : '';
  const queryKey = ['users', stringify(params || {})];

  return useQuery({
    queryKey,
    queryFn: () =>
      get<GetUsersResponse>(`${bffServiceUrl}/users${queryParams}`),
    select: ({ users }) => users,
    ...options,
  });
};
