import React from 'react';
import { Checkbox } from '@breeze-ai/ui-library';
import { TextField, Typography } from '@breezeai-frontend/cargo-ui';
import { type FormApi } from '@tanstack/react-form';
import type { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';

import type { QuoteFormData } from '../../steps/QuoteForm/QuoteForm';

interface Props {
  form: FormApi<QuoteFormData, typeof zodValidator>;
}

export const LetterOfCreditSelector: React.FC<Props> = ({ form }) => {
  const formData = form.state.values;
  const [showLetterOfCredit, setShowLetterOfCredit] = React.useState(
    Boolean(formData.letterOfCredit),
  );

  const handleLetterOfCreditChange = (checked: boolean) => {
    // reset the field value and validate it
    // need to send null to the backend if the checkbox is unchecked
    if (showLetterOfCredit) {
      form.setFieldValue('letterOfCredit', '');
      form.validateAllFields('submit').catch(console.error);
      const meta = form.getFieldMeta('letterOfCredit');
      if (!meta) return;
      form.setFieldMeta('letterOfCredit', {
        ...meta,
        isDirty: true,
      });
    }
    setShowLetterOfCredit(checked);
  };

  return (
    <div>
      <Typography level="h4" customStyles="xl:col-span-full mt-6 mb-4">
        Letter of credit
      </Typography>
      <Checkbox
        onChange={handleLetterOfCreditChange}
        label="This shipment includes a letter of credit"
        checked={showLetterOfCredit}
        role="letter-of-credit-checkbox"
        data-value={showLetterOfCredit}
        data-testid="letter-of-credit-checkbox"
      />
      <form.Field
        name="letterOfCredit"
        validators={{
          onChange: z
            .string()
            .min(
              showLetterOfCredit ? 1 : 0,
              'Please enter details of letter of credit',
            )
            .max(300, 'Input exceeds maximum character limit of 300'),
        }}
        children={(field) => (
          <TextField
            data-testid="letter-of-credit-text-area"
            customStyles={showLetterOfCredit ? 'mt-4' : 'hidden'}
            inputStyles="min-h-[100px] resize-none"
            variant="area"
            isRequired
            label="Letter of credit"
            description="Letter of credit may not exceed 300 characters"
            placeholder="Enter letter of credit"
            id={field.name}
            name={field.name}
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={field.handleChange}
            isInvalid={field.state.meta.errors?.length > 0}
            errorMessage={field.state.meta.errors.join(', ')}
          />
        )}
      />
    </div>
  );
};
