import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { stringify } from 'query-string';

import { type PlaceSuggestion, QueryLevelEnum } from '../../model/Place';
import { type GetPlaceSuggestionsResponse } from '../../network/apis/places/types';
import {
  type ErrorResponse,
  type FilterableFields,
  type PaginationBaseQueryParams,
} from '../../network/apis/types';
import { get, post, put } from '../../network/apis/utils';
import { type NetworkResponse } from '../../network/AuthRequests';
import { bffServiceUrl } from '../../network/netconfig';
import {
  type CertificateCreationPayload,
  type CertificateModel,
  type CertificatesSortableFields,
  type CertificateUpdatePayload,
  type GetCertificatesResponse,
} from './types';

export const useCreateCertificate = ({
  isUpdate = false,
  options,
}: {
  isUpdate: boolean;
  options?: UseMutationOptions<
    NetworkResponse<CertificateModel>,
    ErrorResponse,
    CertificateCreationPayload | CertificateUpdatePayload
  >;
}) => {
  return useMutation({
    mutationFn: (payload) =>
      isUpdate
        ? put(`${bffServiceUrl}/bff-certificate-only/certificate-only`, payload)
        : post(
            `${bffServiceUrl}/bff-certificate-only/certificate-only`,
            payload,
          ),
    ...options,
  });
};

export const useCertificate = ({
  options,
  policyId,
}: {
  policyId?: string;
  options?: UseQueryOptions<CertificateModel, ErrorResponse, CertificateModel>;
}) => {
  const key = ['certificate', policyId];

  return useQuery({
    enabled: !!policyId,
    queryKey: key,
    queryFn: () =>
      get<CertificateModel>(
        `${bffServiceUrl}/bff-certificate-only/${policyId}`,
      ),
    ...options,
  });
};

export const useCertificateSuspense = ({ policyId }: { policyId?: string }) => {
  const key = ['certificate', policyId];

  return useSuspenseQuery({
    queryKey: key,
    // TODO: remove the null check and use component composition as described: https://github.com/TanStack/query/discussions/6206#discussioncomment-7343078
    queryFn: () =>
      policyId
        ? get<CertificateModel>(
            `${bffServiceUrl}/bff-certificate-only/${policyId}`,
          )
        : null,
  });
};

export const useCertificates = ({
  options,
  params,
}: {
  options?: UseQueryOptions<
    NetworkResponse<GetCertificatesResponse>,
    ErrorResponse,
    GetCertificatesResponse
  >;
  params?: PaginationBaseQueryParams<
    CertificatesSortableFields,
    FilterableFields
  >;
}) => {
  const queryParams = params ? `?${stringify(params)}` : '';
  const queryKey = ['certificates', queryParams];
  return useQuery({
    queryKey,
    queryFn: () =>
      post<GetCertificatesResponse>(
        `${bffServiceUrl}/bff-certificate-only/get`,
        queryParams,
      ),
    ...options,
  });
};

export const usePlaces = ({
  options,
  params,
}: {
  params?: { query?: string; session_token: string };
  options?: UseQueryOptions<
    GetPlaceSuggestionsResponse,
    ErrorResponse,
    {
      results: Array<PlaceSuggestion>;
      session_token: string;
    },
    Array<string>
  >;
}) => {
  const queryParams = params
    ? `?${stringify({
        query_text: params.query,
        session_token: params.session_token,
        query_level_type: QueryLevelEnum.STREET,
      })}`
    : '';
  const queryKey = ['places', queryParams];

  return useQuery({
    queryKey,
    queryFn: () =>
      get<GetPlaceSuggestionsResponse>(
        `${bffServiceUrl}/locations/places${queryParams}`,
      ),
    enabled: !!params?.query,
    ...options,
  });
};
