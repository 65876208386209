import { useSuspenseQuery } from '@tanstack/react-query';

import { bffServiceUrl } from '../../netconfig';
import { get } from '../utils';
import { normalizeShipmentResponse } from './normalizers';
import { type ShipmentModel } from './types';

export const useShipmentsSuspense = ({
  shipmentId,
}: {
  shipmentId: string | undefined;
}) => {
  return useSuspenseQuery({
    queryKey: ['shipments', shipmentId],
    queryFn: () =>
      shipmentId
        ? get<ShipmentModel>(`${bffServiceUrl}/bff_shipments/${shipmentId}`)
        : null,
    select: (data) => data && normalizeShipmentResponse(data),
  });
};
