import { type HTMLProps } from 'react';
import { Typography } from '@breeze-ai/ui-library';
import { clsxMerge, Spinner } from '@breezeai-frontend/cargo-ui';

export type DashboardWidgetProps = HTMLProps<HTMLDivElement> & {
  title: string;
  loading?: boolean;
};

export const DashboardWidget = ({
  title,
  loading,
  className,
  children,
  ...props
}: DashboardWidgetProps) => {
  return (
    <div
      className={clsxMerge(
        'rounded-2xl bg-white p-6 flex flex-col min-h-48 flex-1',
        className,
      )}
      {...props}
    >
      {loading ? (
        <Spinner data-testid="insights-page-widget-spinner" />
      ) : (
        <>
          {title && <Typography level="h3">{title}</Typography>}
          {children}
        </>
      )}
    </div>
  );
};
