import {
  type DeepNullable,
  type RecursivePartial,
} from '@breezeai-frontend/cargo-ui';

import { type SupportedCurrencies } from '../../model/CurrencyValue';
import { type Customer } from '../../model/Customer';
import { type Distributor } from '../../model/Distributor';
import { type OpenCover } from '../../model/OpenCover';
import { type Place, type PlaceSuggestion } from '../../model/Place';
import { type Quote } from '../../model/Quote';
import { type ConveyanceType, type Shipment } from '../../model/Shipment';
import { type PDFStatus } from '../../network/apis/policies/types';
import { type QuoteModel } from '../../network/apis/quotes/types';
import { type ShipmentModel } from '../../network/apis/shipments/types';
import { type PaginationBaseResponse } from '../../network/apis/types';
import { type AccountUser } from '../../network/apis/users/types';

export type CertificatesRouteParams = {
  certificateId?: string;
  policyId?: string;
};

export type CertificateModel = {
  id: string;
  customer_id: string;
  quote_id: string;
  issue_date: ISODate;
  certificate_id: ISODate;
  shipment_id: string;
  invoice_id: string;
  certificate_url?: string;
  proof_of_cover_url?: string;
  certificate_status?: PDFStatus;
  proof_of_cover_status?: PDFStatus;
  certificate_created_time?: ISODate;
  proof_of_cover_created_time?: ISODate;
  created_time: ISODate;
  open_cover: OpenCover;
  distributor: Distributor;
  open_cover_id?: string;
  shipment?: ShipmentModel;
  quote?: QuoteModel;
  customer?: Customer;
  created_by_user?: AccountUser;
  associated_with_open_claim?: boolean;
  customer_address?: Place;
};

/**
@deprecated
 * @see CertificateModel
 * This type is deprecated and will be removed in the future.It is only in use for the table which requires normalized data.
 *   **/
export type Certificate = {
  id: string;
  open_cover: OpenCover;
  created_time: ISODate;
  distributor: Distributor;
  external_certificate_id: string;
  open_cover_id?: string;
  insured_value?: string;
  insured_value_currency?: SupportedCurrencies;
  deductible_value?: string;
  deductible_currency?: SupportedCurrencies;
  commodity_currency?: SupportedCurrencies;
  commodity_external_description?: string;
  freight_cost?: string;
  shipment?: Shipment;
  quote?: Quote;
  customer?: Customer;
  certificate_url?: string;
  proof_of_cover_url?: string;
  certificate_status?: PDFStatus;
  proof_of_cover_status?: PDFStatus;
  certificate_created_time?: ISODate;
  proof_of_cover_created_time?: ISODate;
  created_by_user?: AccountUser;
  associated_with_open_claim?: boolean;
};

// TODO: move this type somewhere else as it's being used on other pages
export type MapsApiPayload = Omit<PlaceSuggestion, 'description'>;

export interface CertificateCreationPayload {
  distributor_id: number | null;
  policy_id?: string;

  etd: string;
  eta?: string | null;
  commodity_value: string;
  commodity_currency_code: string;
  commodity_external_description: string;
  external_reference?: string | null;
  goods_marks?: string | null;
  vessel_name?: string | null;

  transport_mode_code: ConveyanceType;
  origin_place_provider_details?: MapsApiPayload | null;
  origin_port_code?: string | null;
  destination_port_code?: string | null;
  destination_place_provider_details?: MapsApiPayload | null;

  loading_transport_mode_code?: ConveyanceType | null;
  loading_place_provider_details?: MapsApiPayload | null;

  delivery_transport_mode_code?: ConveyanceType | null;
  delivery_place_provider_details?: MapsApiPayload | null;
  cargo_owner?: CargoOwner | null;

  letter_of_credit?: string | null;
  issue_date?: string | null;
}

export type CargoOwner = {
  name?: string | null;
  id?: string | null;
  provider_place_details?: MapsApiPayload | null;
};
export type CertificateUpdatePayload = DeepNullable<
  Omit<
    RecursivePartial<CertificateCreationPayload>,
    'distributor_id' | 'policy_id'
  >
> & {
  distributor_id?: number | null;
  policy_id: string;
};

export interface GetCertificatesResponse extends PaginationBaseResponse {
  certificates: CertificateModel[];
}

export type CertificatesSortableFields = 'id' | 'certificate_id' | 'created_at';

export type GetCertificatesNormalizedResponse = Omit<
  GetCertificatesResponse,
  'certificates'
> & {
  certificates: Certificate[];
};

export enum StatusEnum {
  ISSUED = 'policy_issued',
  READY = 'ready_for_policy',
  IN_REVIEW = 'pending_for_breeze_manual_review',
  DECLINED = 'declined',
  ARCHIVED = 'archived',
}
