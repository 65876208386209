import { useUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { type Place } from '../../../model/Place';
import { type PolicyFlowConfigurationResponse } from '../../../network/apis/configurations/types';
import { type PolicyFlowFormsData } from '../context/types';
import { useMappedFormsErrors, usePolicyFlowContext } from './context-hooks';

const useAreRequiredFieldsNotEmpty = (
  configuration: PolicyFlowConfigurationResponse,
) => {
  const user = useUser();
  const { isWtw } = usePlatform();

  const {
    forms: { data },
  } = usePolicyFlowContext();

  const enableReferPolicyFlow = Boolean(
    user?.settings.find((s) => s.name === 'enable_refer_a_policy_flow')?.value,
  );

  const requiredValues = [
    data?.customer?.company_name,
    configuration?.is_shipment_id_required ? data?.externalReference : true,
    configuration?.is_container_numbers_required ? data?.containerIds : true,
    data?.etd,
    enableReferPolicyFlow ? data?.customer?.email : true,
    isWtw ? data?.issueDate : true,
  ];

  if (!isWtw) {
    requiredValues.push(data?.eta);
    requiredValues.push(
      configuration?.is_address_required
        ? (data?.customer?.address as Place)?.provider_place_uuid
        : true,
    );
  }

  return requiredValues.every((v) => !!v);
};

/**
 * An entry point hook for exposing Shipment information form aggregated data
 * to consumer components.
 */
export const useShipmentInformationForm = (
  configuration: PolicyFlowConfigurationResponse,
) => {
  const { forms } = usePolicyFlowContext();
  const areRequiredFieldsFilled = useAreRequiredFieldsNotEmpty(configuration);
  const errorsMap = useMappedFormsErrors();

  const { data } = forms;
  const isValid = areRequiredFieldsFilled && forms.errors.length === 0;

  return {
    data: data as PolicyFlowFormsData,
    errors: errorsMap,
    isValid,
  };
};
