import { create } from 'zustand';

import {
  type FilterableFields,
  type FilterBaseParam,
} from '../../../network/apis/types';
import { type StatusEnum } from '../../certificates/types';
import {
  type StorageLocationStatusEnum,
  type StorageLocationTypeEnum,
} from '../../storage/types';

interface ParamStore<T> {
  filters: FilterBaseParam<T>[];
  updateFilter: (filter: FilterBaseParam<T> | FilterBaseParam<T>[]) => void;
  removeFilter: (field: T) => void;
  clearFilters: () => void;

  selectedTime: undefined | string;
  setSelectedTime: (selectedTimeRange?: string) => void;

  selectedUsers: string[];
  setSelectedUsers: (selectedUsers: string[]) => void;

  selectedStorageLocation: string[];
  setSelectedStorageLocation: (selectedStorageLocation: string[]) => void;

  selectedStatuses: undefined | StatusEnum[];
  setSelectedStatuses: (selectedStatuses?: StatusEnum[]) => void;

  selectedLocationStatuses: undefined | StorageLocationStatusEnum[];
  setSelectedLocationStatuses: (
    selectedLocationStatuses?: StorageLocationStatusEnum[],
  ) => void;

  selectedLocationTypes: undefined | StorageLocationTypeEnum[];
  setSelectedLocationTypes: (
    selectedLocationTypes?: StorageLocationTypeEnum[],
  ) => void;

  selectedCargoOwners: string[];
  setSelectedCargoOwners: (selectedCargoOwners: string[]) => void;

  selectedDistributors: string[];
  setSelectedDistributors: (selectedDistributors: string[]) => void;

  customDateRange: {
    startDate?: string;
    endDate?: string;
  };
  setCustomDateRange: (customDateRange: {
    startDate?: string;
    endDate?: string;
  }) => void;
}

// TODO: Unable to make this generic. Filterable fields are different for each page and it should be passed as a generic type.
// But it is not possible to pass generic type to zustand store in an elegant manner.
// This may lead to a lack of type safety in the future where we may pass wrong filterable fields to the store. They are currently all the same.
export const useFilterStore = create<ParamStore<FilterableFields>>()(
  (set): ParamStore<FilterableFields> => ({
    filters: [],

    updateFilter: (filters) =>
      set((state) => {
        const filtersArr = Array.isArray(filters) ? filters : [filters];

        const nonEmptyFilters = filtersArr.filter(
          (filter) => filter.values !== undefined && filter.values.length !== 0,
        );

        // Upsert filters
        return {
          filters: [
            ...state.filters.filter(
              (filterItem) =>
                !filtersArr.find((filter) => filterItem.field === filter.field),
            ),
            ...nonEmptyFilters,
          ],
        };
      }),
    removeFilter: (field) =>
      set((state) => ({
        filters: state.filters.filter((filter) => filter.field !== field),
      })),
    clearFilters: () =>
      set({
        filters: [],
        selectedTime: undefined,
        selectedUsers: [],
        selectedCargoOwners: [],
      }),
    selectedTime: undefined,
    setSelectedTime: (selectedTime) => set({ selectedTime }),

    selectedUsers: [],
    setSelectedUsers: (selectedUsers) => set({ selectedUsers }),

    selectedStorageLocation: [],
    setSelectedStorageLocation: (selectedStorageLocation) =>
      set({ selectedStorageLocation }),

    selectedLocationStatuses: [],
    setSelectedLocationStatuses: (selectedLocationStatuses) =>
      set({ selectedLocationStatuses }),

    selectedStatuses: [],
    setSelectedStatuses: (selectedStatuses) => set({ selectedStatuses }),

    selectedLocationTypes: [],
    setSelectedLocationTypes: (selectedLocationTypes) =>
      set({ selectedLocationTypes }),

    selectedCargoOwners: [],
    setSelectedCargoOwners: (selectedCargoOwners) =>
      set({ selectedCargoOwners }),

    selectedDistributors: [],
    setSelectedDistributors: (selectedDistributors) =>
      set({ selectedDistributors }),

    customDateRange: { startDate: undefined, endDate: undefined },
    setCustomDateRange: (customDateRange) => set({ customDateRange }),
  }),
);
