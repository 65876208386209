import { usePolicySuspense } from '../../../../network/apis/policies/hooks';
import { useRouteParams } from '../../../../router/router-hooks';
import { AdditionalClauses } from '../../../components/AdditionalClauses/AdditionalClauses';
import { type PolicyRouteParams } from '../PolicyDetailsPage';

export function PolicyAdditionalClauses() {
  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;

  const { data: policy } = usePolicySuspense({ policyId });

  const additionalClause = policy?.quote?.quote_additional_clauses?.[0];

  if (!additionalClause) {
    return null;
  }

  return <AdditionalClauses additionalClause={additionalClause} />;
}
