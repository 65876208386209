import { useState } from 'react';
import { Typography } from '@breeze-ai/ui-library';

import { usePolicy } from '../../../network/apis/policies/hooks';
import { PDFStatus } from '../../../network/apis/policies/types';
import { useRouteParams } from '../../../router/router-hooks';
import { type PolicyRouteParams } from '../../policies/PolicyDetailsPage/PolicyDetailsPage';
import { type Asset, AssetsList } from '../FileList/AssetsList/AssetsList';
import styles from './PollingFileList.module.scss';

const MAX_WAIT_TIME = 20000;

export function PollingFileList() {
  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;
  const [isTimeoutError, setIsTimeoutError] = useState(false);

  const {
    data: policy,
    refetch,
    isFetching,
  } = usePolicy(
    { policyId },
    {
      enabled: !!policyId,
      queryKey: ['policy', policyId!],
      refetchInterval({ state }) {
        setTimeout(() => {
          setIsTimeoutError(true);
        }, MAX_WAIT_TIME);

        const docsInProgress =
          state.data?.proof_of_cover_status !== PDFStatus.InProgress &&
          state.data?.certificate_status !== PDFStatus.InProgress;

        if (isTimeoutError || docsInProgress) {
          return 0;
        }

        return 4000;
      },
    },
  );

  const {
    open_cover,
    certificate_url,
    proof_of_cover_url,
    proof_of_cover_status,
    certificate_status,
    issue_date,
    proof_of_cover_created_time,
  } = policy ?? {};

  const isProofOfCoverLoading =
    (proof_of_cover_status === PDFStatus.InProgress && !isTimeoutError) ||
    isFetching;
  const isCertificateLoading =
    (certificate_status === PDFStatus.InProgress && !isTimeoutError) ||
    isFetching;
  const isProofOfCoverTimeOutError =
    proof_of_cover_status === PDFStatus.InProgress &&
    isTimeoutError &&
    !isProofOfCoverLoading;
  const isCertificateTimeOutError =
    certificate_status === PDFStatus.InProgress &&
    isTimeoutError &&
    !isCertificateLoading;

  const policyFiles: Asset[] = [
    {
      path: certificate_url,
      name: 'Certificate',
      issueDate: issue_date,
      testId: 'policy-certificate',
      isLoading: isCertificateLoading,
      isError: isCertificateTimeOutError,
      isHidden: !certificate_status,
      onTryAgain: refetch,
    },
    {
      path: proof_of_cover_url,
      name: 'Proof of Cover',
      creationDate: proof_of_cover_created_time,
      testId: 'policy-poc',
      isLoading: isProofOfCoverLoading,
      isError: isProofOfCoverTimeOutError,
      isHidden: !proof_of_cover_url,
      onTryAgain: refetch,
    },
    {
      // This doc is not produced async like other docs
      path: open_cover?.terms_and_conditions_url,
      name: 'Policy Full Terms and Conditions',
      testId: 'policy-terms-and-conditions',
      isHidden: !open_cover?.terms_and_conditions_url,
    },
  ];

  return proof_of_cover_status || certificate_status ? (
    <div className={styles.filesLinks}>
      <AssetsList
        assets={policyFiles}
        title={<Typography level="h4">Documents</Typography>}
      />
    </div>
  ) : null;
}
