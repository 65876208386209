import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { stringify } from 'query-string';

import {
  type ClaimsInsights,
  type PoliciesInsightsResponse,
} from '../../model/Insights';
import { type ErrorResponse } from '../../network/apis/types';
import { get, type NetworkResponse, post } from '../../network/apis/utils';
import { bffServiceUrl } from '../../network/netconfig';
import { type BaseInsightsQuery } from './types';

export const usePoliciesInsights = ({
  params,
  options,
}: {
  params: BaseInsightsQuery;
  options?: UseQueryOptions<
    PoliciesInsightsResponse,
    ErrorResponse,
    PoliciesInsightsResponse
  >;
}) => {
  const queryParams = params ? `?${stringify(params)}` : '';

  return useQuery({
    queryKey: ['policiesInsights', queryParams],
    queryFn: () =>
      get<PoliciesInsightsResponse>(
        `${bffServiceUrl}/insights/policies${queryParams}`,
      ),
    ...options,
  });
};

export const useClaimsInsights = ({
  params,
  options,
}: {
  params: BaseInsightsQuery;
  options?: UseQueryOptions<ClaimsInsights, ErrorResponse, ClaimsInsights>;
}) => {
  const queryParams = params ? `?${stringify(params)}` : '';

  return useQuery({
    queryKey: ['claimsInsights', queryParams],
    queryFn: () =>
      get<ClaimsInsights>(`${bffServiceUrl}/insights/claims${queryParams}`),
    ...options,
  });
};

type UseGenerateReportMutationParams = {
  startDate: string;
  endDate: string;
  currencyCode: string;
};
export const useGenerateReportMutation = ({
  options,
}: {
  options?: UseMutationOptions<
    NetworkResponse<{ data: string }>,
    ErrorResponse,
    UseGenerateReportMutationParams
  >;
}) =>
  useMutation({
    mutationFn: (params: UseGenerateReportMutationParams) =>
      post<{ data: string }>(
        `${bffServiceUrl}/insights/reports/generate_and_send_email`,
        {
          period_start: params.startDate,
          period_end: params.endDate,
          currency_code: params.currencyCode,
        },
      ),
    ...options,
  });
