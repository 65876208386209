import { PlatformEnum } from '../context/PlatformContext';

const THEME = import.meta.env.VITE_APP_THEME as PlatformEnum;

const isRecognisedTheme = (theme: PlatformEnum) => {
  return theme === PlatformEnum.BREEZE || theme === PlatformEnum.WTW;
};

export const getTheme = () => {
  if (!isRecognisedTheme(THEME)) {
    console.error('Unrecognised theme');
    return PlatformEnum.BREEZE;
  }
  return THEME;
};
