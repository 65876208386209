import { useCallback, useContext } from 'react';
import { set } from 'lodash';
import { type PartialDeep } from 'type-fest';

import { type Quote } from '../../../model/Quote';
import { type Shipment } from '../../../model/Shipment';
import {
  type ValidationOptions,
  type ValidationResult,
} from '../../../utils/validators';
import { type FlowSteps } from '../constants';
import { PolicyFlowContext } from '../context/PolicyFlowContext';
import {
  type PolicyFlowError,
  type PolicyFlowFormsData,
  type PolicyFlowFormsErrorMap,
} from '../context/types';
import { validate } from '../validators';

export const usePolicyFlowContext = () => {
  const { state } = useContext(PolicyFlowContext);

  return state;
};

export const usePolicyFlowContextAction = () => {
  const { dispatch } = useContext(PolicyFlowContext);

  return dispatch;
};

export const useSetFlowStep = () => {
  const dispatch = usePolicyFlowContextAction();

  return useCallback(
    (payload: FlowSteps) => {
      dispatch({ type: 'set-flow-step', payload });
    },
    [dispatch],
  );
};

export const useSetFlowError = () => {
  const dispatch = usePolicyFlowContextAction();

  return useCallback(
    (payload?: PolicyFlowError) => {
      dispatch({ type: 'set-flow-error', payload });
    },
    [dispatch],
  );
};

export const useSetShipmentData = () => {
  const dispatch = usePolicyFlowContextAction();

  return useCallback(
    (payload: Shipment) => {
      dispatch({ type: 'set-shipment-data', payload });
    },
    [dispatch],
  );
};

export const useSetQuoteData = () => {
  const dispatch = usePolicyFlowContextAction();

  return useCallback(
    (payload: Quote) => {
      dispatch({ type: 'set-quote-data', payload });
      dispatch({
        type: 'set-form-data',
        payload: { customer: payload.customer },
      });
      dispatch({ type: 'reset-form-edits' });
    },
    [dispatch],
  );
};

export const useInitFormData = () => {
  const dispatch = usePolicyFlowContextAction();

  return useCallback(
    (payload: PartialDeep<PolicyFlowFormsData>) => {
      dispatch({ type: 'set-form-data', payload });
    },
    [dispatch],
  );
};

export const useSetFormData = (form: 'quote' | 'shipment-info') => {
  const { quote } = usePolicyFlowContext();
  const isDataUpdate = quote && form === 'quote';

  const dispatch = usePolicyFlowContextAction();

  return useCallback(
    (payload: PartialDeep<PolicyFlowFormsData>) => {
      isDataUpdate
        ? dispatch({ type: 'set-form-data-update', payload })
        : dispatch({ type: 'set-form-data', payload });
    },
    [dispatch, isDataUpdate],
  );
};

export const useSetFormError = () => {
  const dispatch = usePolicyFlowContextAction();

  return useCallback(
    (result: ValidationResult) => {
      dispatch({ type: 'set-form-error', payload: result });
    },
    [dispatch],
  );
};

export const useInputValidation = () => {
  const setFormError = useSetFormError();

  return useCallback(
    (field: string, value: string, options?: ValidationOptions) => {
      const validationResult = validate(field, value, options) ?? true;

      setFormError(validationResult);

      // for marking validation success or failure
      return !validationResult.error;
    },
    [setFormError],
  );
};

/**
 * Serves the inputs validation errors list as a nested structure to match the
 * form's data structure, each has its validation error reason
 */
export const useMappedFormsErrors = (): PolicyFlowFormsErrorMap => {
  const { forms } = usePolicyFlowContext();

  const errorsMap = forms.errors.reduce(
    (result, { field, ...validationResult }) => {
      set(result, field, { ...validationResult });
      return result;
    },
    {} as PolicyFlowFormsErrorMap,
  );

  return errorsMap;
};
