import { useQuery } from '@tanstack/react-query';

import { useUser } from '../../../context/auth/auth-hooks';
import {
  type CurrencyValue,
  type DefaultCurrency,
} from '../../../model/CurrencyValue';
import type { ErrorResponse } from '../../../network/apis/types';
import { get, type NetworkResponse } from '../../../network/apis/utils';
import { bffServiceUrl } from '../../../network/netconfig';

export const useStorageLocationConfiguration = () => {
  const currentUser = useUser();
  return useQuery<
    NetworkResponse<{
      policy_insured_value_limit: number;
      default_currency: CurrencyValue;
    }>,
    ErrorResponse,
    {
      default_currency: DefaultCurrency;
      policy_insured_value_limit: number;
    }
  >({
    queryKey: ['storage-location-configuration'],
    queryFn: () =>
      get(
        `${bffServiceUrl}/bff-storage-locations/${currentUser?.distributor.id}/configuration`,
      ),
    enabled: !!currentUser?.distributor.id,
  });
};
