import {
  Select,
  SelectItem,
  type SelectProps,
} from '@breezeai-frontend/cargo-ui';
import { kebabCase } from 'lodash-es';

import { type Distributor } from '../../../../../model/Distributor';
import labels from '../../../../labels';

interface DistributorSelectProps
  extends Omit<SelectProps<Distributor>, 'children'> {
  isWtw: boolean;
  distributors: Distributor[];
  isLoading: boolean;
}

export function DistributorSelect({
  distributors,
  isLoading,
  ...props
}: DistributorSelectProps) {
  return (
    <Select
      isRequired={props.isWtw}
      isLoading={isLoading}
      data-testid="distributor-selector"
      validationBehavior="aria"
      label={labels.fields.distributor}
      placeholder={`Select ${labels.fields.distributor}`}
      selectedValueTestId="distributor-selected-value"
      {...props}
    >
      {distributors.map(({ legal_name, id }) => (
        <SelectItem
          key={id}
          id={id}
          textValue={legal_name}
          data-testid={`select-option-${kebabCase(legal_name)}`}
        >
          {legal_name}
        </SelectItem>
      ))}
    </Select>
  );
}
